<template>
  <CommonHeader />
  <MyServiceDetails />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import MyServiceDetails from "@/components/Services/MyServiceDetails.vue";

export default {
  name: "MyServiceDetailsView",
  components: {
    CommonHeader,
    MyServiceDetails,
  },
};
</script>

<style scoped></style>
