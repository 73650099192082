<template>
  <CommonHeader />
  <!-- calc(100dvh-376px) 100dvh - (86px header + 290px footer) -->
  <main class="py-12 bg-background min-h-[calc(100dvh-376px)]">
    <RegisterUser />
  </main>
  <CookiesDisclaimer />
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import RegisterUser from "@/components/SignIn/RegisterUser.vue";
import CommonFooter from "@/components/common/Footer.vue";
import CookiesDisclaimer from "@/components/common/CookiesDisclaimer.vue";

export default {
  metaInfo: {
    title: "User Registry | Biglo",
  },
  name: "UserRegister",
  components: {
    CommonHeader,
    RegisterUser,
    CommonFooter,
    CookiesDisclaimer,
  },
};
</script>

<style scoped></style>
