<template>
  <div class="register-service">
    <CommonHeader />
    <RegisterServiceForm />
  </div>
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import RegisterServiceForm from "@/components/Services/RegisterServiceForm.vue";

export default {
  name: "SpaceRegistry",
  metaInfo: {
    title: "Space Registry | Biglo",
  },
  components: {
    CommonHeader,
    RegisterServiceForm,
  },
};
</script>

<style scoped></style>
