import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import axios from "axios";
import routes from "./routes.js";

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

// Axios response interceptor for error handling
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check if the response exists before accessing its status
    if (error.response) {
      if (error.response.status === 401) {
        // Handle unauthorized access
        router.push("/log-in");
      }
    } else {
      // Handle other types of errors (e.g., network errors)
      console.error("An error occurred:", error.message);
    }
    return Promise.reject(error);
  },
);

// Navigation guard for authentication
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters["auth/isAuthenticated"]
  ) {
    next({
      path: "/log-in",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
