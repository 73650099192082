<template>
  <CommonHeader />
  <!-- calc(100dvh-376px) 100dvh - (86px header + 290px footer) -->
  <main class="py-12 bg-background min-h-[calc(100dvh-376px)]">
    <PasswordReset />
  </main>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import PasswordReset from "@/components/SignIn/PasswordReset.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
  name: "UserVerificationView",
  components: {
    CommonHeader,
    PasswordReset,
    CommonFooter,
  },
};
</script>

<style scoped></style>
