<template>
  <!-- Steps Nav Buttons -->
  <nav class="flex md:flex-nowrap mx-auto min-md:maw-w-fit">
    <div
      class="flex mx-auto md:flex-nowrap md:overflow-x-auto md:snap-x md:scroll-px-1 md:ml-1 items-center overflow-y-hidden"
    >
      <NavButton
        v-for="(step, index) in steps"
        :key="step.name"
        :stepName="step.name"
        :iconClass="step.icon"
        :isCurrent="currentStep === step.name"
        :isActive="
          isNavStepValid(step.name) &&
          (index === 0 || isStepValid(steps[index - 1].name))
        "
        :showSeparator="index > 0"
        :separatorClass="index > 0 ? 'separator-blue' : ''"
        :additionalClasses="
          index === 0
            ? 'snap-start'
            : index === steps.length - 1
              ? 'snap-end'
              : ''
        "
        class="shrink-0 relative"
        @navigate="navigateStep"
      />
    </div>
  </nav>

  <!-- Input Nav Buttons & Main -->
  <div class="wrapper">
    <!-- Register Space Form -->
    <form class="register-space-form" @submit.prevent="handleTypeStep">
      <!-- 1st Step: Type -->
      <div class="step-container type-step" v-if="currentStep === 'type'">
        <span class="step-form-title">
          <i class="fa-solid fa-clipboard-question"></i>
          1st step: Type</span
        >

        <!-- Conventional Input -->
        <div class="input-container conventional-input">
          <input
            type="radio"
            id="conventional"
            name="type"
            value="conventional"
            v-model="typeData.type"
          />
          <label for="conventional">Conventional Warehouse</label>
        </div>

        <!-- High Intensity Input -->
        <div class="input-container high-intensity-input">
          <input
            type="radio"
            id="highIntensity"
            name="type"
            value="highIntensity"
            v-model="typeData.type"
          />
          <label for="highIntensity">High Intensity Warehouse</label>
        </div>

        <!-- Automated Input -->
        <div class="input-container automated-input">
          <input
            type="radio"
            id="automated"
            name="type"
            value="automated"
            v-model="typeData.type"
          />
          <label for="automated">Automated Warehouse</label>
        </div>

        <!-- Refrigerated Input -->
        <div class="input-container refrigerated-input">
          <input
            type="radio"
            id="refrigerated"
            name="type"
            value="refrigerated"
            v-model="typeData.type"
          />
          <label for="refrigerated">Refrigerated Warehouse</label>
        </div>

        <!-- Temporary Input -->
        <div class="input-container temporary-input">
          <input
            type="radio"
            id="temporary"
            name="type"
            value="temporary"
            v-model="typeData.type"
          />
          <label for="temporary">Temporary / Demountable Warehouse</label>
        </div>

        <!-- Floor Input -->
        <div class="input-container floor-input">
          <input
            type="radio"
            id="floor"
            name="type"
            value="floor"
            v-model="typeData.type"
          />
          <label for="floor">Logistics floor</label>
        </div>
      </div>

      <!-- 2nd Step: Operations -->
      <OperationsStep
        v-if="currentStep === 'operations'"
        v-model:operations-data="operationsData"
        @change-step="navigateStep"
      />

      <!-- 3rd Step: Location -->
      <div
        class="step-container location-step"
        v-if="currentStep === 'location'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-map-location-dot"></i>
          3rd step: Location</span
        >

        <!-- Country Input -->
        <CountryInput
          :initial-country="locationData.country"
          @country-selected="updateCountry"
          @change-input="navigateInput('location', $event)"
        />

        <!-- Region Input -->
        <div
          class="input-container region-input"
          v-if="currentInput === 'region'"
        >
          <label for="region-input">
            <i class="fa-solid fa-location-crosshairs"></i>
            Region / Province / State
          </label>
          <div class="input-and-button">
            <input
              ref="regionInput"
              autocapitalize="on"
              autocorrect="off"
              autocomplete="off"
              type="text"
              v-model="locationData.region"
              placeholder="Enter the region"
              id="region-input"
            />
            <button
              :disabled="locationData.region.length === 0"
              @click="navigateInput('location', 'city')"
              id="submit-region"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- City Input -->
        <div class="input-container city-input" v-if="currentInput === 'city'">
          <label for="city-input">
            <i class="fa-solid fa-city"></i>
            City
          </label>
          <div class="input-and-button">
            <input
              ref="cityInput"
              autocapitalize="on"
              autocorrect="off"
              autocomplete="off"
              type="text"
              v-model="locationData.city"
              placeholder="Enter the city"
              id="city-input"
            />
            <button
              :disabled="locationData.city.length === 0"
              @click="navigateInput('location', 'zip')"
              id="submit-city"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- ZIP Input -->
        <div class="input-container zip-input" v-if="currentInput === 'zip'">
          <label for="zip-input">
            <i class="fa-solid fa-envelope"></i>
            ZIP
          </label>
          <div class="input-and-button">
            <input
              ref="zipInput"
              autocomplete="off"
              type="text"
              v-model="locationData.zip"
              placeholder="Enter the ZIP code"
              id="zip-input"
            />
            <button
              :disabled="locationData.zip.length === 0"
              @click="navigateInput('location', 'address')"
              id="submit-zip"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- Address Input -->
        <div
          class="input-container address-input"
          v-if="currentInput === 'address'"
        >
          <label for="address-input">
            <i class="fa-solid fa-house"></i>
            Address
          </label>
          <div class="input-and-button">
            <input
              ref="addressInput"
              autocapitalize="on"
              autocorrect="off"
              autocomplete="off"
              type="text"
              v-model="locationData.address"
              placeholder="Enter the address"
              id="address-input"
            />
            <button
              :disabled="locationData.address.length === 0"
              @click="navigateStep('dimensions')"
              id="submit-address"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- 4th Step: Dimensions -->
      <div
        class="step-container dimensions-step"
        v-if="currentStep === 'dimensions'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-ruler"></i>
          4th step: Dimensions</span
        >

        <!-- Surface Input -->
        <div
          class="input-container surface-input"
          v-if="dimensionsCurrentInput === 'surface'"
        >
          <label for="surface-input">
            <i class="fa-solid fa-maximize"></i>
            Surface / Area
          </label>
          <div class="input-and-button">
            <input
              ref="surfaceInput"
              autocomplete="off"
              type="number"
              min="0"
              v-model="dimensionsData.surface"
              placeholder="Enter the surface (in m²)"
              id="surface-input"
            />
            <button
              :disabled="dimensionsData.surface.length === 0"
              @click="navigateInput('dimensions', 'height')"
              id="submit-surface"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- Height Input -->
        <div
          class="input-container height-input"
          v-if="dimensionsCurrentInput === 'height'"
        >
          <label for="height-input">
            <i class="fa-solid fa-arrows-up-down"></i>
            Height
          </label>
          <div class="input-and-button">
            <input
              ref="heightInput"
              autocomplete="off"
              type="number"
              min="0"
              v-model="dimensionsData.height"
              placeholder="Enter the height (in m)"
              id="height-input"
            />
            <button
              :disabled="dimensionsData.height.length === 0"
              @click="navigateInput('dimensions', 'palletPositions')"
              id="submit-height"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- Pallet Positions Input -->
        <div
          class="input-container palletPositions-input"
          v-if="dimensionsCurrentInput === 'palletPositions'"
        >
          <label for="palletPositions-input">
            <i class="fa-solid fa-pallet"></i>
            Pallet Positions
          </label>
          <div class="input-and-button">
            <input
              ref="palletPositionsInput"
              autocomplete="off"
              type="number"
              min="0"
              v-model="dimensionsData.palletPositions"
              placeholder="Enter the pallet positions"
              id="palletPositions-input"
            />
            <button
              :disabled="dimensionsData.palletPositions.length === 0"
              @click="navigateStep('equipment')"
              id="submit-palletPositions"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- 5th Step: Equipment -->
      <EquipmentStep
        v-if="currentStep === 'equipment'"
        v-model:equipment-data="equipmentData"
        @change-step="navigateStep"
      />

      <!-- 6th Step: Storage -->
      <StorageStep
        v-if="currentStep === 'storage'"
        v-model:storage-data="storageData"
        v-model:current-input="storageCurrentInput"
        @change-step="navigateStep"
        @change-input="navigateInput"
      />

      <!-- 7th Step: Goods -->
      <GoodsStep
        v-if="currentStep === 'goods'"
        v-model:goods-data="goodsData"
        @change-step="navigateStep"
      />

      <!-- 8th Step: Licenses -->
      <LicensesStep
        v-if="currentStep === 'licenses'"
        v-model:licenses-data="licensesData"
        @change-step="navigateStep"
      />

      <!-- 9th Step: Multimedia -->
      <div
        class="step-container multimedia-step"
        v-if="currentStep === 'multimedia'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-photo-film"></i>
          9th step: Multimedia
        </span>

        <!-- Images Dropzone -->
        <div
          class="input-container images-input"
          v-if="multimediaCurrentInput === 'images'"
        >
          <label for="images-input">
            <i :class="typeIcon('images')"></i>
            Images
          </label>
          <Dropzone
            @file-upload="handleFileUpload('images', $event)"
            accept="image/*"
            placeholder="Drag or select images"
          />
          <button
            class="next-step-button"
            @click="navigateInput('multimedia', 'videos')"
          >
            Next Input
          </button>
        </div>

        <!-- Videos Dropzone
        <div
          class="input-container videos-input"
          v-if="multimediaCurrentInput === 'videos'"
        >
          <label for="videos-input">
            <i :class="typeIcon('videos')"></i>
            Videos
          </label>
          <Dropzone
            @file-upload="handleFileUpload('videos', $event)"
            accept="video/*"
            placeholder="Drag or select videos"
          />
          <button
            class="next-step-button"
            @click="navigateInput('multimedia', 'blueprints')"
          >
            Next Input
          </button>
        </div>

        Blueprints Dropzone
        <div
          class="input-container blueprints-input"
          v-if="multimediaCurrentInput === 'blueprints'"
        >
          <label for="blueprints-input">
            <i :class="typeIcon('blueprints')"></i>
            Blueprints
          </label>
          <Dropzone
            @file-upload="handleFileUpload('blueprints', $event)"
            accept=".pdf,.doc,.docx,.png,.jpeg"
            placeholder="Drag or select blueprints"
          />
          <button class="next-step-button" @click="navigateStep('description')">
            Next Step
          </button>
        </div> -->
      </div>
      <!-- Next Step Button -->
      <button
        class="next-step-button"
        @click="nextStep"
        :disabled="!canProceedToNextStep()"
      >
        Next Step
      </button>

      <!-- 10th Step: Description -->
      <div
        class="step-container description-step"
        v-if="currentStep === 'description'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-text"></i>
          10th step: Description
        </span>

        <!-- Description Input -->
        <div class="input-container description-input">
          <textarea
            ref="descriptionInput"
            autocapitalize="on"
            autocorrect="on"
            autocomplete="off"
            v-model="descriptionData.descriptionText"
            placeholder="Enter the description, please."
            id="description-input"
          ></textarea>
        </div>
        <!--
        <button
          class="next-step-button"
          @click="submitForm()"
          :disabled="
            !arePreviousStepsFilled('description') ||
            descriptionData.descriptionText.length < 10
          "
        >
          Submit Space
        </button>
        -->
      </div>
    </form>
  </div>
</template>

<script>
import NavButton from "@/components/common/Forms/NavButton.vue";
import OperationsStep from "@/components/Spaces/Steps/2Operations.vue";
import EquipmentStep from "@/components/Spaces/Steps/5Equipment.vue";
import StorageStep from "@/components/Spaces/Steps/6Storage.vue";
import GoodsStep from "@/components/Spaces/Steps/7Goods.vue";
import LicensesStep from "@/components/Spaces/Steps/8Licenses.vue";
import Dropzone from "@/components/common/Dropzone.vue";
import CountryInput from "@/components/common/Forms/CountryInputButton.vue";
import apiClient from "@/api";

export default {
  name: "RegisterSpaceForm",
  components: {
    NavButton,
    OperationsStep,
    EquipmentStep,
    StorageStep,
    GoodsStep,
    LicensesStep,
    Dropzone,
    CountryInput,
  },
  data() {
    return {
      // Current active step and sub-step
      currentStep: "type",
      currentInput: null,

      steps: [
        { name: "type", icon: "fa-solid fa-clipboard-question" },
        { name: "operations", icon: "fa-solid fa-screwdriver-wrench" },
        { name: "location", icon: "fa-solid fa-map-location-dot" },
        { name: "dimensions", icon: "fa-solid fa-ruler" },
        { name: "equipment", icon: "fa-solid fa-forklift" },
        { name: "storage", icon: "fa-solid fa-warehouse-full" },
        { name: "goods", icon: "fa-solid fa-box-circle-check" },
        { name: "licenses", icon: "fa-solid fa-file-certificate" },
        { name: "multimedia", icon: "fa-solid fa-photo-film" },
        { name: "description", icon: "fa-solid fa-text" },
      ],

      // Sub-steps for steps with multiple inputs
      stepInputs: {
        location: ["country", "region", "city", "zip", "address"],
        dimensions: ["surface", "height", "palletPositions"],
        storage: ["blockStorage", "directStorage", "automatedStorage"],
        multimedia: ["images", "videos", "blueprints"],
      },

      // Form Data
      typeData: { type: "" },
      operationsData: {
        eCommerce: false,
        crossDocking: false,
        temporary: false,
        distribution: false,
        consolidation: false,
      },
      locationData: {
        country: "",
        region: "",
        city: "",
        zip: "",
        address: "",
      },
      dimensionsData: { surface: "", height: "", palletPositions: "" },
      equipmentData: {
        //loadingDockAmount: false,
        temperatureHumidityControl: false,
        refrigeratedChamber: false,
        //ammountParkingSpaces: false,
        fireProtectionSystem: false,
        securitySystem: false,
        //maneuveringArea: false,
        //yard: false,
        //office: false,
      },
      storageData: {
        blockStorage: { rackPushBack: false, dynamicRack: false },
        directStorage: {
          conventionalRack: false,
          mobileBaseRack: false,
          cantileverRack: false,
          driveInRack: false,
          palletShuttleRack: false,
        },
        automatedStorage: {
          verticalCarousel: false,
          horizontalCarousel: false,
          stackerCrane: false,
          verticalShuttle: false,
        },
      },
      goodsData: {
        bulk: false,
        dry: false,
        perishable: false,
        chemical: false,
        raw: false,
        construction: false,
        machinery: false,
      },
      licensesData: {
        iso9001: false,
        iso14001: false,
        iso45001: false,
        ohsas18001: false,
        haccp: false,
        customs: false,
        chemicalHandling: false,
        fireSafety: false,
        breeam: false,
        leed: false,
      },
      multimediaData: { images: [], videos: [], blueprints: [] },
      descriptionData: { descriptionText: "" },

      // Get user.id from vue store
      userId: this.$store.state.user ? this.$store.state.user._id : null,
    };
  },
  computed: {
    currentStepComponent() {
      const componentsMap = {
        type: "TypeStep",
        operations: "OperationsStep",
        location: "LocationStep",
        dimensions: "DimensionsStep",
        equipment: "EquipmentStep",
        storage: "StorageStep",
        goods: "GoodsStep",
        licenses: "LicensesStep",
        multimedia: "MultimediaStep",
        description: "DescriptionStep",
      };
      return componentsMap[this.currentStep];
    },
  },
  methods: {
    canProceedToNextStep() {
      const currentIndex = this.steps.findIndex(
        (step) => step.name === this.currentStep,
      );

      if (currentIndex === -1) return false; // Safety check for invalid step

      // Validate current step and ensure all prior steps are valid
      const currentStepValid = this.isStepValid(this.currentStep);
      const allPreviousStepsValid = this.steps
        .slice(0, currentIndex)
        .every((step) => this.isStepValid(step.name));

      return currentStepValid && allPreviousStepsValid;
    },
    nextStep() {
      const inputs = this.stepInputs[this.currentStep];

      // Handle sub-step navigation if inputs exist
      if (inputs && this.currentInput) {
        const currentInputIndex = inputs.indexOf(this.currentInput);
        if (currentInputIndex < inputs.length - 1) {
          this.currentInput = inputs[currentInputIndex + 1];
          return;
        }
      }

      // Move to the next step if all validations pass
      const currentIndex = this.steps.findIndex(
        (step) => step.name === this.currentStep,
      );
      const currentStepValid = this.isStepValid(this.currentStep);

      if (currentStepValid && currentIndex < this.steps.length - 1) {
        this.navigateStep(this.steps[currentIndex + 1].name);
      }
    },
    updateCountry(selectedCountry) {
      this.locationData.country = selectedCountry;
    },
    navigateInput(step, nextInput) {
      if (this.stepInputs[step]?.includes(nextInput)) {
        this.currentInput = nextInput;
      }
    },
    navigateStep(nextStep) {
      this.currentStep = nextStep;
      this.currentInput = this.stepInputs[nextStep]?.[0] || null;
    },
    isNavStepValid(stepName) {
      const validators = {
        type: () => true,
        operations: () => !!this.typeData.type,
        location: () =>
          Object.values(this.operationsData).some((value) => value === true),
        dimensions: () =>
          Object.values(this.locationData).every(
            (value) => value.trim() !== "",
          ),
        equipment: () =>
          Object.values(this.dimensionsData).every((value) => value !== ""),
        storage: () =>
          Object.values(this.equipmentData).some((value) => value === true),
        goods: () =>
          Object.values(this.storageData).some((section) =>
            Object.values(section).some((value) => value === true),
          ),
        licenses: () =>
          Object.values(this.goodsData).some((value) => value === true),
        multimedia: () =>
          Object.values(this.licensesData).some((value) => value === true),
        description: () =>
          Object.values(this.licensesData).some((value) => value === true),
      };
      return validators[stepName] ? validators[stepName]() : false;
    },
    isStepValid(stepName) {
      const validators = {
        type: () => !!this.typeData.type,
        operations: () =>
          Object.values(this.operationsData).some((value) => value === true),
        location: () =>
          Object.values(this.locationData).every(
            (value) => value.trim() !== "",
          ),
        dimensions: () =>
          Object.values(this.dimensionsData).every((value) => value !== ""),
        equipment: () =>
          Object.values(this.equipmentData).some((value) => value === true),
        storage: () =>
          Object.values(this.storageData).some((section) =>
            Object.values(section).some((value) => value === true),
          ),
        goods: () =>
          Object.values(this.goodsData).some((value) => value === true),
        licenses: () =>
          Object.values(this.licensesData).some((value) => value === true),
        multimedia: () =>
          Object.values(this.licensesData).some((value) => value === true),
        description: () => !!this.descriptionData.descriptionText.trim(),
      };
      return validators[stepName] ? validators[stepName]() : false;
    },

    handleFileUpload(type, event) {
      const files = event.target.files || event;
      this.multimediaData[type] = files;
    },

    /* fa-icon data */
    typeIcon(type) {
      const icons = {
        images: "fa-solid fa-image",
        videos: "fa-solid fa-video",
        blueprints: "fa-solid fa-pen-ruler",
      };
      return icons[type];
    },

    /* Capitalizies first letter */
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    async submitForm() {
      try {
        const payload = {
          typeData: this.typeData,
          operationsData: this.operationsData,
          locationData: this.locationData,
          dimensionsData: this.dimensionsData,
          equipmentData: this.equipmentData,
          storageData: this.storageData,
          goodsData: this.goodsData,
          licensesData: this.licensesData,
          multimediaData: this.multimediaData,
          descriptionData: this.descriptionData,
          userId: this.userId,
        };
        await apiClient.post("/spaces/register", payload);
        this.$router.push({ path: "/your-account" });
      } catch (error) {
        console.error(
          "Error submitting form:",
          error.response?.data || error.message,
        );
      }
    },
  },
  mounted() {
    // Initialize first sub-step for steps with inputs
    if (this.stepInputs[this.currentStep]) {
      this.currentInput = this.stepInputs[this.currentStep][0];
    } else {
      this.currentInput = null;
    }
  },
};
</script>

<style scoped>
/* Main Wrapper */
.wrapper {
  display: flex;
  flex-direction: row;
  margin: 50px auto;
}

/* Register Space Form */
.register-space-form {
  position: relative;
  margin: auto;
  width: fit-content;
}
.step-container {
  position: relative;
  margin: auto;
}
.step-form-title {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  color: #004aad;
  text-align: center;
}
.step-form-subtitle {
  display: block;
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #004aad;
  text-align: center;
}
input[type="radio"],
input[type="radio"]:checked {
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
}

/* Style for custom radio buttons */
.input-container {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 1rem auto;
}
.input-container input[type="radio"]:before {
  content: "";
  display: inline-block;
  width: 20px; /* Size of the checkbox */
  height: 20px; /* Size of the checkbox */
  border: 1px solid #004aad; /* Border color */
  border-radius: 4px; /* Rounded corners */
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Checked state style */
.input-container input[type="radio"]:checked:before {
  content: "✕";
  color: #efb615;
  background-color: #004aad;
  display: inline-block;
  width: 20px; /* Size of the checkbox */
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: 800;
  border-radius: 4px; /* Rounded corners */
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}
.next-step-button {
  position: relative;
  padding: 10px 16px;
  color: #efb615;
  font-weight: 500;
  width: max-content;
  margin: 1rem calc(50% - 57px);
  border: 1px solid #004aad;
  background-color: #004aad;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}
.next-step-button[disabled],
.next-step-button[disabled]:hover {
  cursor: not-allowed;
  background-color: #a9a9a9;
  color: #004aad;
}
.next-step-button:hover {
  color: #004aad;
  background-color: #efb615;
  border-color: #004aad;
}
.next-step-button[disabled]:hover::after {
  content: "Please, fill the step first";
  font-weight: 400;
  position: absolute;
  background-color: #004aad;
  color: #efb615;
  width: max-content;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  z-index: 2;
  pointer-events: none;
}
/* Switch Checkbox */

ol {
  list-style: none;
}

ol label {
  cursor: pointer;
}

[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.switches {
  max-width: 500px;
  width: 95%;
  margin: 16px auto 0;
  border-radius: 5px;
  color: #004ad0;
}

.switches li {
  position: relative;
  counter-increment: switchCounter;
}

.switches li:not(:last-child) {
  border-bottom: 1px solid #434257;
}

.switches li::before {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  font-size: 2rem;
  font-weight: bold;
  color: #ff4b77;
}

.switches label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.switches span:last-child {
  position: relative;
  width: 50px;
  height: 26px;
  border-radius: 15px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: #da0000;
  transition: all 0.3s;
}

.switches span:last-child::before,
.switches span:last-child::after {
  content: "";
  position: absolute;
}

.switches span:last-child::before {
  left: 1px;
  top: 1px;
  width: 24px;
  height: 24px;
  background: #e8e9ed;
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.3s;
}

.switches span:last-child::after {
  top: 50%;
  right: 8px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/uncheck-switcher.svg);
  background-size: 12px 12px;
}

.switches [type="checkbox"]:checked + label span:last-child {
  background: #00d084;
}

.switches [type="checkbox"]:checked + label span:last-child::before {
  transform: translateX(24px);
}

.switches [type="checkbox"]:checked + label span:last-child::after {
  width: 14px;
  height: 14px;
  right: auto;
  left: 8px;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/checkmark-switcher.svg);
  background-size: 14px 14px;
}

/* Location Step */
.input-and-button {
  margin: 16px 0 0 0;
  display: flex;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  border-radius: 8px;
  flex-direction: row;
}
.location-step .input-container,
.dimensions-step .input-container {
  padding-left: 0px !important;
  position: relative;
}
.location-step input,
.dimensions-step input {
  border-radius: 8px 0 0 8px;
  width: 400px;
  margin: auto;
  padding: 0.67rem 1rem;
}

.location-step .button,
.dimensions-step .button {
  padding: 0.67rem 1rem;
  background-color: #004aad;
  border-radius: 0 8px 8px 0;
  color: #efb615;
}
.location-step .button:hover,
.dimensions-step .button:hover {
  background-color: #efb615;
  color: #004aad;
}
.location-step .input-container label,
.dimensions-step .input-container label,
.input-group > label,
.multimedia-step .input-container label {
  color: #004aad;
  font-weight: 500;
  font-size: large;
  margin: 16px 0 16px 50px;
}
.storage-step .step-form-subtitle {
  margin-bottom: 16px;
}
.button[disabled] {
  position: relative;
  content: "";
  background-color: #a9a9a9;
  color: #004aad;
  cursor: not-allowed;
}
.button[disabled]:hover {
  background-color: #a9a9a9;
  color: #004aad;
  cursor: not-allowed;
}
.button[disabled]:hover::after {
  content: "Please, fill the field";
  position: absolute;
  background-color: #004aad;
  color: #efb615;
  width: max-content;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  z-index: 2;
  pointer-events: none;
}

/* Storage Step */
#submit-automatedStorage[disabled]:hover::after {
  content: "Please, select at least one method of storagement";
}

/* Multimedia Step */
.input-file-container {
  width: 100%;
  height: 167px;
  display: flex;
  cursor: pointer;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed #004aad;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.multimedia-step .input-container :where(i, p) {
  color: #004aad;
}

.input-file-container > i {
  font-size: 50px;
}
.input-file-container > p {
  margin-top: 15px;
  font-size: 16px;
}
::selection {
  color: #fff;
  background: #004aad;
}
section .row {
  margin-bottom: 10px;
  background: #e9f0ff;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section .row i {
  color: #004aad;
  font-size: 30px;
}
section .details span {
  font-size: 14px;
}
.progress-area .row .content {
  width: 100%;
  margin-left: 15px;
}
.progress-area .details {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: space-between;
}
.progress-area .content .progress-bar {
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 30px;
}
.content .progress-bar .progress {
  height: 100%;
  width: 0%;
  background: #004aad;
  border-radius: inherit;
}
.uploaded-area {
  max-height: 232px;
  overflow-y: scroll;
}
.uploaded-area.onprogress {
  max-height: 150px;
}
.uploaded-area::-webkit-scrollbar {
  width: 0px;
}
.uploaded-area .row .content {
  display: flex;
  align-items: center;
}
.uploaded-area .row .details {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}
.uploaded-area .row .details .size {
  color: #404040;
  font-size: 11px;
}
.uploaded-area i.fa-check {
  font-size: 16px;
}
.description-step textarea {
  border-radius: 5px;
  width: 500px;
  height: 200px; /* Adjust the height as needed */
  margin: auto;
  padding: 0.67rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #004aad; /* Customize the border color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  resize: none;
}
</style>
