<template>
  <div class="p-6 bg-surface text-primary rounded-lg shadow-lg">
    <h1 class="text-2xl font-bold flex items-center space-x-2 mb-3">
      <i class="fa-solid fa-user"></i>
      <span>User Details</span>
    </h1>
    <div class="bg-primary h-0.5 w-full mb-4"></div>

    <!-- Loading State -->
    <div v-if="isLoading" class="text-center py-4">
      <i class="fa-solid fa-spinner fa-spin"></i> Loading user data...
    </div>

    <!-- User Details Form -->
    <form v-else @submit.prevent="updateUser" class="space-y-6">
      <!-- Logo Section -->
      <div>
        <p class="block font-semibold mb-1">Logo</p>
        <div class="flex items-center space-x-4">
          <!-- Display ImageView only if logo is defined -->
          <img
            :src="logoUrl"
            v-if="userLogo"
            class="rounded-md max-w-48 max-h-48"
          />
          <DropzoneLogo v-if="!userLogo" v-model="logo" class="rounded-md" />
        </div>
      </div>

      <!-- Form Fields for User Data -->
      <div class="space-y-4">
        <div>
          <label for="user-name" class="block font-semibold mb-1">Name</label>
          <input
            type="text"
            name="user-name"
            v-model="formData.name"
            id="user-name"
            placeholder="Name"
            class="w-full px-4 py-3 border border-primary bg-background text-primary rounded-lg"
          />
        </div>

        <div>
          <label for="user-email" class="block font-semibold mb-1">Email</label>
          <input
            type="email"
            name="user-email"
            id="user-email"
            v-model="formData.email"
            placeholder="Email"
            class="w-full px-4 py-3 border border-primary bg-background text-primary rounded-lg"
          />
        </div>
        <span v-if="!isEmailValid()" class="text-status-error text-sm">{{
          emailErrorMessage
        }}</span>
      </div>

      <!-- Telephone Input -->
      <div>
        <label for="user-telephone" class="block font-semibold mb-1"
          >Telephone</label
        >
        <div class="flex">
          <TelephoneCountryPrefixUpdater
            v-model="formData.telephoneCountryPrefix"
            :initial-prefix="formData.telephoneCountryPrefix"
          />
          <input
            type="tel"
            name="user-telephone"
            id="user-telephone"
            :value="formData.telephone"
            @input="handlePhoneInput"
            placeholder="Telephone number"
            class="flex-1 px-4 py-3 border border-primary bg-background text-primary rounded-md rounded-l-none"
          />
        </div>
        <span
          v-if="!isPhoneValid() && formData.telephone"
          class="text-status-error text-sm"
          >{{ telephoneErrorMessage }}</span
        >
      </div>

      <!-- Additional Fields -->
      <div class="space-y-4">
        <div>
          <label for="user-website" class="block font-semibold mb-1"
            >Website</label
          >
          <input
            type="url"
            v-model="formData.website"
            id="user-website"
            placeholder="Website"
            class="w-full px-4 py-3 border border-primary bg-background text-primary rounded-lg"
          />
        </div>
        <div>
          <label for="user-country" class="block font-semibold mb-1"
            >Country</label
          >
          <input
            type="text"
            v-model="formData.country"
            id="user-country"
            placeholder="Country"
            class="w-full px-4 py-3 border border-primary bg-background text-primary rounded-lg"
          />
        </div>
      </div>

      <!-- Password Management Section -->
      <div class="space-y-6">
        <div>
          <label for="user-password" class="block font-semibold mb-1"
            >New Password</label
          >
          <div class="flex items-center transition duration-200">
            <input
              :type="newPasswordFieldType"
              autocomplete="off"
              name="user-password"
              id="user-password"
              v-model="formData.newPassword"
              class="flex-1 outline-none border border-r-0 border-primary bg-background text-primary rounded-l-lg px-4 py-3"
            />
            <button
              type="button"
              @click="togglePassword('new')"
              class="py-3 px-4 rounded-r-lg text-white bg-brand-darkblue border border-l-0 border-brand-yellow hover:text-brand-yellow focus:text-brand-yellow"
              aria-label="Toggle password visibility"
            >
              <i :class="newPasswordIcon"></i>
            </button>
          </div>
          <span
            v-if="!isPasswordComplex() && formData.newPassword.length > 0"
            class="text-status-error text-sm"
            >{{ passwordErrorMessage }}</span
          >
        </div>

        <div v-if="!isFormInvalid() && !noChangesMade">
          <label for="current-password" class="block font-semibold mb-1"
            >Current Password</label
          >
          <div class="flex items-center rounded-md">
            <input
              :type="currentPasswordFieldType"
              name="current-password"
              id="current-password"
              v-model="formData.currentPassword"
              required
              class="flex-1 outline-none border border-r-0 border-primary bg-background text-primary rounded-l-lg px-4 py-3"
            />
            <button
              type="button"
              @click="togglePassword('current')"
              class="py-3 px-4 rounded-r-lg text-white bg-brand-darkblue border border-l-0 border-brand-yellow hover:text-brand-yellow focus:text-brand-yellow"
              aria-label="Toggle password visibility"
            >
              <i :class="currentPasswordIcon"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Save Button -->
      <button
        type="submit"
        :disabled="isFormInvalid() || !formData.currentPassword"
        class="w-full bg-brand-darkblue text-white border border-brand-yellow py-3 mt-4 rounded-md font-semibold shadow-md hover:text-brand-yellow focus:text-brand-yellow transition-colors disabled:border-grey disabled:text-grey disabled:cursor-not-allowed"
      >
        Save Changes
      </button>
    </form>

    <!-- Verification & Notifications -->
    <RemindEmailVerification
      v-if="!user.isVerified"
      class="block mt-6 mx-auto text-sm font-semibold"
    />
    <NotificationCustom
      v-for="(notification, index) in notifications"
      :key="index"
      :type="notification.type"
      :message="notification.message"
      :duration="notification.duration"
      class="mt-4"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NotificationCustom from "@/components/common/NotificationCustom.vue";
import RemindEmailVerification from "@/components/SignIn/RemindEmailVerification.vue";
import DropzoneLogo from "@/components/common/DropzoneLogo.vue";
import TelephoneCountryPrefixUpdater from "@/components/common/Forms/TelephoneCountryPrefixUpdater.vue";

export default {
  name: "UserDetails",
  components: {
    TelephoneCountryPrefixUpdater,
    NotificationCustom,
    RemindEmailVerification,
    DropzoneLogo,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        telephoneCountryPrefix: "",
        telephone: "",
        website: "",
        country: "",
        newPassword: "",
        currentPassword: "",
      },
      newPasswordFieldType: "password",
      currentPasswordFieldType: "password",
      newPasswordIcon: "fa-solid fa-eye-slash",
      currentPasswordIcon: "fa-solid fa-eye-slash",
      emailErrorMessage: "",
      telephoneErrorMessage: "",
      passwordErrorMessage: "",
      notifications: [],
      fallbackImage: require("@/assets/images/user-fallback.jpg"),
      isDataLoaded: false,
      emailValidationTimeout: null,
      phoneValidationTimeout: null,
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "user", // Use the user getter to access user data
      userLogoBlob: "userLogoBlob", // Use the userLogoBlob getter to access the logo
      isLoading: "isLoading", // Access the loading state
    }),
    logoUrl() {
      // Use the userLogoBlob from the store to display the logo
      return this.userLogoBlob
        ? URL.createObjectURL(this.userLogoBlob)
        : this.fallbackImage;
    },
    dataText() {
      return this.noChangesMade()
        ? "You haven't made any change"
        : this.isFieldEmpty()
          ? "You cannot leave formerly filled fields empty"
          : "Check every field";
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser && newUser._id && !this.isDataLoaded) {
          this.isDataLoaded = true;
          this.formData = {
            name: newUser.name || "",
            email: newUser.email || "",
            telephoneCountryPrefix: newUser.telephoneCountryPrefix || "",
            telephone: newUser.telephone || "",
            website: newUser.website || "",
            country: newUser.country || "",
          };
          this.fetchUserData();
        }
      },
    },
  },
  methods: {
    ...mapActions("auth", ["fetchUser", "updateUserAction"]),

    handleEmailInput(event) {
      this.formData.email = event.target.value;
    },

    handlePhoneInput(event) {
      this.formData.telephone = event.target.value.replace(/\D/g, "");
    },

    async fetchUserData() {
      if (this.user?._id && !this.isDataLoaded) {
        try {
          await this.fetchUser(this.user._id);
          this.isDataLoaded = true;
        } catch (error) {
          console.error("Error fetching user data:", error);
          this.addNotification("Error fetching user data.", "error");
        }
      }
    },

    async updateUser() {
      if (this.isFormInvalid()) return;
      try {
        const updatedUser = {
          ...this.user,
          ...this.formData,
          password: this.formData.newPassword,
        };
        await this.updateUserAction({
          user: updatedUser,
          currentPassword: this.formData.currentPassword,
        });
        this.formData.newPassword = "";
        this.formData.currentPassword = "";
        this.addNotification("User details updated successfully!", "success");
      } catch (error) {
        if (error.response?.status === 401) {
          this.addNotification("Invalid current password.", "error");
        } else {
          this.addNotification("Error submitting form.", "error");
        }
      }
    },

    togglePassword(version) {
      if (version === "new") {
        this.newPasswordFieldType =
          this.newPasswordFieldType === "password" ? "text" : "password";
        this.newPasswordIcon =
          this.newPasswordFieldType === "password"
            ? "fa-solid fa-eye-slash"
            : "fa-solid fa-eye";
      } else if (version === "current") {
        this.currentPasswordFieldType =
          this.currentPasswordFieldType === "password" ? "text" : "password";
        this.currentPasswordIcon =
          this.currentPasswordFieldType === "password"
            ? "fa-solid fa-eye-slash"
            : "fa-solid fa-eye";
      }
    },

    isFormInvalid() {
      return (
        !this.isEmailValid() ||
        !this.isPhoneValid() ||
        !this.isPasswordComplex() ||
        this.isFieldEmpty()
      );
    },

    isFieldEmpty() {
      return (
        this.formData.name === "" ||
        this.formData.email === "" ||
        this.formData.telephoneCountryPrefix === "" ||
        this.formData.telephone === "" ||
        this.formData.website === "" ||
        this.formData.country === ""
      );
    },

    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let isValid = true;

      if (this.formData.email.includes("+")) {
        this.emailErrorMessage = 'This field cannot contain "+"';
        isValid = false;
      } else if (!emailRegex.test(this.formData.email)) {
        this.emailErrorMessage = "Invalid email format";
        isValid = false;
      } else {
        this.emailErrorMessage = "";
      }

      return isValid;
    },

    isPasswordComplex() {
      if (!this.formData.newPassword) return true;

      const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
      const password = this.formData.newPassword;

      if (password.length < 10) {
        this.passwordErrorMessage =
          "Password must contain at least 10 characters";
        return false;
      }
      if (!symbolRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one special symbol";
        return false;
      }
      if (!uppercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one uppercase letter";
        return false;
      }
      if (!lowercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one lowercase letter";
        return false;
      }
      if (!numberRegex.test(password)) {
        this.passwordErrorMessage = "Password must contain at least one number";
        return false;
      }

      this.passwordErrorMessage = "";
      return true;
    },

    isPhoneValid() {
      const phoneRegex = /^[0-9]{9,15}$/;
      let isValid = true;
      if (!phoneRegex.test(this.formData.telephone)) {
        this.telephoneErrorMessage = "Telephone must be 9-15 digits";
        isValid = false;
      } else {
        this.telephoneErrorMessage = "";
      }

      return isValid;
    },

    addNotification(message, type) {
      const newNotification = {
        type,
        message,
        duration: 5000,
      };
      this.notifications.push(newNotification);
      setTimeout(
        () =>
          (this.notifications = this.notifications.filter(
            (n) => n !== newNotification,
          )),
        newNotification.duration,
      );
    },

    noChangesMade() {
      return Object.keys(this.formData).every(
        (key) => this.formData[key] === (this.user[key] || ""),
      );
    },
  },
  mounted() {
    this.fetchUserData();
  },
};
</script>

<style scoped>
input {
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus {
  border-color: #0080ff !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 128 255 / 0.75) !important;
}
</style>
