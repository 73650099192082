<template>
  <CommonHeader />
  <ServiceDetails />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import ServiceDetails from "@/components/Search/Services/ServiceDetails.vue";

export default {
  name: "ServiceDetailsView",
  components: {
    CommonHeader,
    ServiceDetails,
  },
};
</script>

<style scoped></style>
