<template>
  <CommonHeader />
  <!-- calc(100dvh-376px) 100dvh - (86px header + 290px footer) -->
  <main class="py-12 bg-background min-h-[calc(100dvh-376px)]">
    <PasswordForgotten />
  </main>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import PasswordForgotten from "@/components/SignIn/PasswordForgotten.vue";
import CommonFooter from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ForgottenPassword",
  metaInfo: {
    title: "Forgotten Password | Biglo",
  },
  components: {
    CommonHeader,
    PasswordForgotten,
    CommonFooter,
  },
});
</script>

<style scoped></style>
