<template>
  <main class="pt-20 theme-transition min-h-screen bg-background text-primary">
    <!-- Hero Section -->
    <section class="relative py-30 px-4 min-md:px-30 bg-background">
      <div class="max-w-5xl mx-auto text-center">
        <h1 class="text-4xl min-md:text-5xl font-bold mb-10">
          <span class="text-brand-yellow">Biglo</span>:
          <span class="text-status-info">Connecting Logistics Ecosystems</span>
        </h1>
        <p class="text-xl min-md:text-2xl text-primary max-w-170 mx-auto">
          The 360° platform bridging logistics operators, space providers and
          seekers through secure, ethical technology
        </p>
      </div>
    </section>

    <!-- Dynamic Section Component -->
    <section
      v-for="(section, index) in sections"
      :key="index"
      class="border-t-2 border-status-info py-30 px-4 min-md:px-30"
      :class="section.background"
    >
      <div class="max-w-5xl mx-auto text-center">
        <h2 class="text-3xl min-md:text-4xl font-bold mb-16 text-invertedAlt">
          {{ section.title }}
        </h2>

        <!-- Grid Layouts -->
        <div
          v-if="section.grid"
          class="grid min-md:grid-cols-3 gap-4 text-center"
        >
          <div
            v-for="(item, itemIndex) in section.items"
            :key="itemIndex"
            class="p-6 rounded-lg"
            :class="item.cardClass"
          >
            <div
              class="w-16 h-16 rounded-full mb-6 mx-auto flex items-center justify-center"
              :class="item.iconClass"
            >
              <span class="text-2xl">{{ item.icon }}</span>
            </div>
            <h3 class="font-bold text-xl text-primary mb-4">
              {{ item.title }}
            </h3>
            <p class="text-secondary">
              {{ item.description }}
            </p>
          </div>
        </div>

        <!-- Mission Statement Special Layout -->
        <div
          v-else-if="section.type === 'mission'"
          class="grid min-md:grid-cols-2 gap-8"
        >
          <div
            v-for="(item, itemIndex) in section.items"
            :key="itemIndex"
            class="bg-background text-secondary rounded-2xl p-6 min-md:p-8 shadow-lg"
          >
            <h3 class="text-xl font-bold text-primary mb-4 text-center">
              <i :class="item.iconClass" class="mr-2"></i>
              {{ item.title }}
            </h3>
            <p
              class="text-justify"
              v-for="(paragraph, pIndex) in item.description"
              :key="pIndex"
            >
              {{ paragraph }}
            </p>
          </div>
        </div>

        <!-- CTA Section -->
        <div v-else-if="section.type === 'cta'" class="max-w-170 mx-auto">
          <p class="text-lg text-primary mb-20">
            {{ section.content }}
          </p>
          <router-link
            v-if="isAuthenticated"
            to="/register-service-or-space"
            class="border border-brand-yellow bg-brand-darkblue text-brand-yellow px-4 py-3 rounded-lg font-medium hover:bg-brand-yellow hover:text-brand-darkblue transition-all"
          >
            {{ section.authenticatedButton }}
          </router-link>
          <router-link
            v-else
            to="/sign-up"
            class="border border-brand-yellow bg-brand-darkblue text-white px-4 py-3 rounded-lg font-medium hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowtransition-all"
          >
            {{ section.guestButton }}
          </router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    sections() {
      return [
        {
          title: "Revolutionizing Logistics Connections",
          background: "bg-surface",
          type: "mission",
          items: [
            {
              iconClass: "fa-solid fa-globe",
              title: "For Logistics Partners & Space Owners",
              description: [
                "Maximize your assets' potential through our secure network of verified seekers.",
                "Whether you manage fleets or own logistics facilities, connect with quality partners that align with your operational standards and business needs.",
              ],
            },
            {
              iconClass: "fa-solid fa-magnifying-glass",
              title: "For Logistics Seekers & Solution Finders",
              description: [
                "Find tailored solutions through our verified network of operators and facilities.",
                "Access real-time availability, transparent pricing and compliance guarantees. Ensure efficiency and reliability with partners that meet your logistics demands.",
              ],
            },
          ],
        },
        {
          title: "Built on Integrity",
          background: "bg-background",
          grid: true,
          items: this.values.map((value) => ({
            ...value,
            cardClass: "bg-surface",
            iconClass: "bg-brand-darkblue",
          })),
        },
        {
          title: "Enterprise-Grade Security",
          background: "bg-surface",
          grid: true,
          items: this.securityFeatures.map((feature) => ({
            ...feature,
            cardClass: "bg-background",
            iconClass: "bg-brand-darkblue",
          })),
        },
        {
          title: "Biglo's 360° Advantage",
          background: "bg-background",
          grid: true,
          items: this.advantages.map((advantage) => ({
            ...advantage,
            cardClass: "bg-surface",
            iconClass: "bg-brand-darkblue",
          })),
        },
        {
          title: "Strengthen the Logistics Chain",
          background: "bg-surface",
          type: "cta",
          content:
            "Join professionals elevating the industry through secure collaboration",
          authenticatedButton: "Register your service/space!",
          guestButton: "Register your business!",
        },
      ];
    },
  },
  data() {
    return {
      values: [
        {
          icon: "🤝",
          title: "Sector Collaboration",
          description:
            "Creating symbiotic relationships that elevate entire supply chains.",
        },
        {
          icon: "🔏",
          title: "Privacy First",
          description: "Collecting only essential data - never sold or shared",
        },
        {
          icon: "⚡",
          title: "Operational Excellence",
          description: "AI-powered matching for optimal resource allocation",
        },
      ],
      securityFeatures: [
        {
          icon: "🔐",
          title: "Zero Third-Party Cookies",
          description: "We respect your digital footprint and privacy",
        },
        {
          icon: "🛡️",
          title: "Military-Grade Encryption",
          description: "JWT tokens, CSRF protection, and secure data hashing",
        },
        {
          icon: "📦",
          title: "Data Minimalism",
          description: "We only store strictly essential information",
        },
      ],
      advantages: [
        {
          icon: "🚛",
          title: "Operators",
          description: "Optimize fleet utilization with smart matching",
        },
        {
          icon: "🏭",
          title: "Space Owners",
          description: "Transform dormant assets into revenue streams",
        },
        {
          icon: "🎯",
          title: "Seekers",
          description: "Instant access to vetted logistics solutions",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
