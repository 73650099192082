<template>
  <div class="text-primary max-w-screen-xl mx-auto bg-surface">
    <!-- Title and Toggle -->
    <div
      class="title-results border-b-2 border-status-info pb-2 mb-8 flex justify-between align-text-top"
    >
      <h1 class="text-left text-primary text-2xl font-bold">Service Results</h1>

      <div class="flex items-center gap-4">
        <!-- "x services found" Message -->
        <p
          v-if="!isLoading && services.length"
          class="text-secondary text-right text-lg rounded-lg shadow-md"
        >
          <strong>{{ services.length }}</strong> services found
        </p>

        <!-- Toggle Button -->
        <button
          @click="toggleView"
          class="p-2 bg-brand-darkblue text-brand-white border border-brand-yellow hover:text-brand-yellow focus:text-brand-yellow rounded-lg transition-shadow lg:hidden"
          aria-label="Toggle View List/Grid"
        >
          <i
            :class="{
              'fa-solid fa-list': !isGridView,
              'fa-solid fa-table-cells': isGridView,
            }"
          ></i>
        </button>
      </div>
    </div>

    <!-- Loading State -->
    <div
      v-if="isLoading"
      class="text-center text-primary text-lg p-5 bg-gray-100 rounded-lg shadow-md mt-5"
      role="status"
      aria-live="polite"
    >
      Loading...
    </div>

    <!-- Results List -->
    <div v-else>
      <div
        v-if="services.length"
        :class="[
          'service-list',
          isGridView
            ? 'grid gap-6 grid-cols-1 min-lg:grid-cols-2 min-xl:grid-cols-3'
            : 'flex flex-col gap-6',
        ]"
      >
        <div
          v-for="service in services"
          :key="service._id"
          class="service-item bg-background border border-grey text-surface hover:text-background rounded-lg shadow-md shadow-brand-darkblue/50 hover:shadow-lg hover:border-brand-darkblue hover:shadow-brand-darkblue transition-transform duration-200 ease-in-out cursor-pointer"
          @click="viewDetails(service)"
          role="button"
          tabindex="0"
          @keydown.enter="viewDetails(service)"
          @keydown.space="viewDetails(service)"
        >
          <!-- Image and Details Container -->
          <div :class="['flex', isGridView ? 'flex-col' : '']">
            <!-- Image -->
            <div
              :class="[
                'flex items-center justify-center overflow-hidden bg-white',
                isGridView ? 'w-full h-48' : 'w-48 h-48 flex-shrink-0',
              ]"
            >
              <img
                v-if="service?.multimedia?.images?.[0]"
                :src="service.multimedia.images[0]"
                :alt="`Image for ${service.userName}'s service`"
                loading="lazy"
                :class="[
                  'service-image w-full h-full object-cover',
                  isGridView ? 'rounded-t-lg' : 'rounded-l-lg',
                ]"
              />
              <svg
                v-else
                class="service-icon w-full h-full fill-current"
                role="img"
                aria-label="Default service icon"
              >
                <use href="/images/sprite.svg#service-icon" />
              </svg>
            </div>

            <!-- Details -->
            <div class="service-details p-4 flex flex-col gap-2 flex-grow">
              <h2 class="text-status-info text-lg font-semibold truncate">
                {{ service.userName }}
              </h2>
              <p class="text-secondary text-sm truncate">
                {{ service.location.city }}, {{ service.location.country }}
              </p>
              <p class="text-tertiary text-sm truncate">
                Surface: {{ service.dimensions?.surface || "N/A" }} m² | Pallet
                Positions:
                {{ service.dimensions?.palletPositions || "N/A" }}
              </p>

              <!-- Additional Details -->
              <div v-if="!isGridView" class="mt-2 space-y-1">
                <p class="text-secondary text-sm">
                  <span class="font-semibold">Height:</span>
                  {{ service.dimensions?.height || "N/A" }} m
                </p>
                <p class="text-secondary text-sm">
                  <span class="font-semibold">Transportation:</span>
                  {{
                    service.transportation?.aerialTransportation ? "Aerial" : ""
                  }}
                  {{
                    service.transportation?.groundTransportation ? "Ground" : ""
                  }}
                  {{
                    service.transportation?.maritimeTransportation
                      ? "Maritime"
                      : ""
                  }}
                </p>
                <p class="text-secondary text-sm">
                  <span class="font-semibold">Storage:</span>
                  {{ service.storageAndInventory?.dry ? "Dry" : "" }}
                  {{
                    service.storageAndInventory?.refrigerated
                      ? "Refrigerated"
                      : ""
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- No Results -->
      <div
        v-else
        class="no-services text-center text-primary text-lg p-5 bg-gray-100 rounded-lg shadow-md mt-5"
        role="status"
      >
        No services found. Try adjusting your search filters.
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api";

export default {
  name: "ServicesSearchResults",
  data() {
    return {
      services: [], // Holds the list of services fetched based on search criteria
      isLoading: false, // Loading state
      isGridView: true, // Toggle between grid and list view
    };
  },
  methods: {
    async fetchServices(filters = {}) {
      this.isLoading = true; // Set loading state to true
      try {
        const response = await apiClient.get("/services/filter", {
          params: filters,
        });
        this.services = response.data.services || [];
      } catch (error) {
        console.error("Error loading services:", error.message);
        this.services = []; // Clear results on error
      } finally {
        this.isLoading = false; // Set loading state to false
      }
    },
    viewDetails(service) {
      // Navigate to service details
      this.$router.push({
        name: "ServiceSearchDetails",
        params: { serviceId: service._id },
      });
    },
    toggleView() {
      this.isGridView = !this.isGridView; // Toggle between grid and list view
    },
  },
  mounted() {
    // Initial fetch of services
    this.fetchServices();
  },
};
</script>

<style scoped>
.service-item:hover {
  transform: translateY(-4px);
}
</style>
