<template>
  <div
    id="container"
    class="fixed bottom-0 left-0 right-0 bg-surface text-primary py-3 px-4 border-t-2 border-status-info z-50 theme-transition backdrop-blur"
    :class="{ hidden: isDismissed }"
  >
    <div
      class="container mx-auto flex flex-col items-center justify-between gap-3"
    >
      <p class="text-sm/6 text-center">
        <span class="mr-1">🔒</span>
        <span class="font-medium">Your Privacy, Our Priority.</span>
        <br />
        At <span class="font-semibold">Biglo</span>, we deeply respect your
        privacy. We use only essential cookies to keep our platform secure —
        nothing more.<br />
        We <span class="font-medium">never</span> track, analyze, or sell your
        data. Learn more in our
        <router-link
          to="/cookies-policy"
          class="text-invertedAlt hover:text-inverted focus:text-inverted focus:underline hover:underline transition-colors"
        >
          Cookies' Policy</router-link
        >.
      </p>
      <button
        @click="dismiss"
        class="flex items-center gap-2 px-4 py-2 bg-brand-darkblue border-brand-yellow font-medium text-white rounded-lg border hover:text-brand-yellow focus:text-brand-yellow !duration-0 whitespace-nowrap"
      >
        <span class="text-lg !duration-0 select-none">✓</span>
        Understood
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDismissed: false,
    };
  },
  mounted() {
    this.isDismissed =
      localStorage.getItem("cookiesDisclaimerDismissed") === "true";
  },
  methods: {
    dismiss() {
      localStorage.setItem("cookiesDisclaimerDismissed", "true");
      this.isDismissed = true;
    },
  },
};
</script>

<style scoped>
#container {
  box-shadow: 0 -33px 67px #004aad;
}
</style>
