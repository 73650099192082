<template>
  <section class="bg-surfaceSaturated py-25 theme-transition">
    <div class="mx-auto max-w-[90dvw]">
      <div
        class="flex flex-row lg:flex-col justify-start h-full w-full m-auto items-center mt-[86px]"
      >
        <!-- Text Content -->
        <div class="items-start">
          <h1
            class="text-4xl min-md:text-5xl font-bold text-primary text-center"
          >
            OPTIMIZE YOUR SPACE AND IMPROVE YOUR LOGISTICS
          </h1>
          <p class="text-2xl md:text-xl text-secondary my-6 text-center">
            Tailored storage solutions designed to meet <br />your business
            needs
          </p>
          <router-link
            to="/sign-up"
            class="block mx-auto w-fit border border-brand-yellow bg-brand-darkblue mt-5 px-5 py-3 rounded-lg text-white hover:text-brand-yellow focus:text-brand-yellow !duration-0 !transition-none"
            aria-label="Sign up now to optimize your logistics"
            role="button"
          >
            <span class="font-medium">Sign up Now</span>
          </router-link>
        </div>

        <!-- Image -->
        <div
          class="flex flex-col justify-center items-center w-auto max-w-full h-full md:mt-6"
        >
          <img
            width="681"
            height="456"
            sizes="(max-width: 479px) 90vw, (max-width: 767px) 43vw, (max-width: 991px) 46vw, 48vw"
            srcset="
              images/Warehouse-p-500.png   500w,
              images/Warehouse-p-800.png   800w,
              images/Warehouse-p-1080.png 1080w,
              images/Warehouse-p-1600.png 1600w,
              images/Warehouse-p-2000.png 2000w,
              images/Warehouse-p-2600.png 2600w,
              images/Warehouse-p-3200.png 3200w,
              images/Warehouse.png        4000w
            "
            alt="Warehouse image"
            class="w-full"
            loading="eager"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>

<style scoped></style>
