<template>
  <div class="p-6 max-w-screen-xl mx-auto bg-background">
    <div class="p-6 bg-surface shadow-lg rounded-lg overflow-hidden">
      <!-- Header Section -->
      <div class="relative">
        <img
          v-if="service?.multimedia?.images?.[0]"
          :src="service.multimedia.images[0]"
          alt="Service Image"
          class="object-contain mx-auto"
        />
        <svg v-else class="text-primary object-contain mx-auto fill-current">
          <use href="/images/sprite.svg#service-icon" />
        </svg>
      </div>

      <!-- Service Details Section -->
      <div class="p-6">
        <!-- User Name -->
        <h1 class="text-brand-darkblue font-bold text-2xl mb-4">
          {{ service?.userName }}
        </h1>

        <!-- Description -->
        <div class="mb-6">
          <h2 class="text-primary font-semibold text-lg mb-2">Description</h2>
          <p class="text-secondary text-sm leading-6">
            {{ service?.description }}
          </p>
        </div>

        <!-- Address -->
        <div class="mb-6">
          <h2 class="text-primary font-semibold text-lg mb-2">Address</h2>
          <p class="text-secondary text-sm">
            {{ service?.location?.address }},
            {{ service?.location?.zip }}
          </p>
          <p class="text-secondary text-sm">
            {{ service?.location?.city }}, {{ service?.location?.region }},
            {{ service?.location?.country }}
          </p>
        </div>

        <!-- Logistics Details -->
        <div class="mb-6">
          <h2
            class="text-primary text-center font-semibold text-xl pb-4 mb-8 border-b border-brand-darkblue"
          >
            Logistics Information
          </h2>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <h3 class="font-bold text-primary text-sm">
                International Coverage
              </h3>
              <p class="text-secondary text-sm">
                Europe:
                {{
                  service?.location?.internationalCoverage?.europe
                    ? "Yes"
                    : "No"
                }}
                <br />
                Asia:
                {{
                  service?.location?.internationalCoverage?.asia ? "Yes" : "No"
                }}
                <br />
                N. America:
                {{
                  service?.location?.internationalCoverage?.northAmerica
                    ? "Yes"
                    : "No"
                }}
                <br />
                S. America:
                {{
                  service?.location?.internationalCoverage?.southAmerica
                    ? "Yes"
                    : "No"
                }}
                <br />
                Africa:
                {{
                  service?.location?.internationalCoverage?.africa
                    ? "Yes"
                    : "No"
                }}
              </p>
            </div>
            <div>
              <h3 class="font-bold text-primary text-sm">PL Types</h3>
              <ul class="space-y-1">
                <li
                  v-for="(value, key) in service?.plType"
                  :key="key"
                  class="bg-brand-darkblue text-white text-xs font-semibold rounded-full px-3 py-1 inline-block mr-1"
                >
                  {{ key }}: {{ value ? "Yes" : "No" }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Dimensions -->
        <div class="mb-6">
          <h2 class="text-primary font-semibold text-lg mb-2">Dimensions</h2>
          <ul class="space-y-1 text-secondary text-sm">
            <li>
              <span class="font-semibold">Surface:</span>
              {{ service?.dimensions?.surface }} m²
            </li>
            <li>
              <span class="font-semibold">Height:</span>
              {{ service?.dimensions?.height }} m
            </li>
            <li>
              <span class="font-semibold">Pallet Positions:</span>
              {{ service?.dimensions?.palletPositions }}
            </li>
          </ul>
        </div>

        <!-- Transportation -->
        <div class="mb-6">
          <h2 class="text-primary font-semibold text-lg mb-2">
            Transportation
          </h2>
          <ul class="space-y-1 text-secondary text-sm">
            <li>
              Aerial:
              {{ service?.transportation?.aerialTransportation ? "Yes" : "No" }}
            </li>
            <li>
              Ground:
              {{ service?.transportation?.groundTransportation ? "Yes" : "No" }}
            </li>
            <li>
              Maritime:
              {{
                service?.transportation?.maritimeTransportation ? "Yes" : "No"
              }}
            </li>
          </ul>
        </div>

        <!-- Storage -->
        <div class="mb-6">
          <h2 class="text-primary font-semibold text-lg mb-2">Storage</h2>
          <ul class="space-y-1 text-secondary text-sm">
            <li>Dry: {{ service?.storageAndInventory?.dry ? "Yes" : "No" }}</li>
            <li>
              Refrigerated:
              {{ service?.storageAndInventory?.refrigerated ? "Yes" : "No" }}
            </li>
            <li>
              Freezer:
              {{ service?.storageAndInventory?.freezer ? "Yes" : "No" }}
            </li>
          </ul>
        </div>

        <!-- Contact Button -->
        <div class="mt-6 flex justify-center">
          <a
            target="_blank"
            :href="emailHref"
            class="bg-brand-darkblue border border-brand-yellow hover:text-brand-yellow focus:text-brand-yellow text-white font-semibold py-2 px-4 rounded shadow-lg"
          >
            Contact owner
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api";

export default {
  data() {
    return {
      service: null,
      serviceId: this.$route.params.serviceId,
      fallbackImage: require("@/assets/images/service-icon.png"),
    };
  },
  computed: {
    emailHref() {
      const email = "biglospain@gmail.com";
      const subject = `Interested in ${this.service?.userName} (id: ${this.serviceId})`;
      return `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    },
  },
  created() {
    this.fetchServiceDetails();
  },
  methods: {
    async fetchServiceDetails() {
      try {
        const response = await apiClient.get(
          `/services/${this.$route.params.serviceId}`,
        );
        this.service = response.data; // Service now includes GCS URL
      } catch (error) {
        console.error("Error fetching service details:", error.message);
      }
    },
  },
};
</script>

<style scoped></style>
