<template>
  <div class="header-container shadow">
    <CommonHeader />
  </div>
  <div class="wrapper">
    <SpaceDetails />
  </div>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import SpaceDetails from "@/components/Spaces/SpaceDetails.vue";
import CommonFooter from "@/components/common/Footer.vue";

export default {
  name: "SpaceDetailsView",
  components: {
    CommonHeader,
    SpaceDetails,
    CommonFooter,
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 90vw;
  margin: 16px auto;
}
</style>
