<template>
  <div class="bg-surface relative block">
    <!-- Div to match the Results.vue border-b simetry -->
    <div class="border-b-2 border-status-info pb-2 mb-8">
      <!-- Filter Header -->
      <h1
        class="text-left text-primary text-2xl font-bold mb-2.5"
        id="filters-heading"
      >
        Filters
      </h1>
    </div>

    <form @submit.prevent="applyFilters" aria-labelledby="filters-heading">
      <!-- Location -->
      <fieldset class="py-4 border-b border-status-info relative block">
        <legend class="text-primary font-semibold text-lg">Location</legend>

        <!-- International Coverage Checkbox -->
        <div class="m-auto relative">
          <ol class="switch block relative cursor-pointer mt-0.5" role="none">
            <li class="relative">
              <input
                type="checkbox"
                id="international-coverage"
                v-model="filters.location.internationalCoverage"
                @change="updateLocationType"
                aria-controls="location-select continent-options"
              />
              <label for="international-coverage">
                <span class="text-sm text-secondary">
                  International Coverage
                </span>
                <span role="presentation"></span>
              </label>
            </li>
          </ol>
        </div>

        <!-- Dynamic Location Type Selector -->
        <select
          id="location-select"
          v-model="filters.location.type"
          class="border border-grey-light rounded-lg p-2 w-full text-sm my-2 bg-background text-secondary"
          aria-label="Location type"
        >
          <option value="country">Country</option>
          <option value="region">Region</option>
          <option value="city">City</option>
          <option
            v-if="filters.location.internationalCoverage"
            value="continents"
          >
            Continents
          </option>
        </select>

        <!-- Country input -->
        <div class="relative block" v-if="filters.location.type === 'country'">
          <label for="country-input" class="sr-only">Search country</label>
          <input
            class="border border-grey-light rounded-lg p-2 w-full text-sm bg-background text-secondary mb-2"
            ref="countryInput"
            autocapitalize="on"
            autocorrect="off"
            autocomplete="off"
            type="text"
            v-model="countrySearchQuery"
            @input="
              restrictToLetters($event);
              filterCountries();
            "
            @keydown="navigateCountryList"
            @focus="filterCountries()"
            @blur="
              setTimeout(() => {
                filteredCountries = [];
              }, 200)
            "
            placeholder="Enter the country"
            id="country-input"
            aria-autocomplete="list"
            aria-controls="country-list"
            aria-haspopup="listbox"
            :aria-activedescendant="
              selectedCountryIndex >= 0
                ? `country-option-${selectedCountryIndex}`
                : null
            "
          />
          <ul
            id="country-list"
            v-show="filteredCountries.length > 0"
            v-if="filters.location.type === 'country'"
            role="listbox"
            aria-labelledby="country-input"
          >
            <li
              v-for="(country, index) in filteredCountries"
              :key="country"
              :id="`country-option-${index}`"
              :class="{ selected: index === selectedCountryIndex }"
              @click="selectCountry(country)"
              @mousedown.prevent
              role="option"
              :aria-selected="index === selectedCountryIndex"
            >
              {{ country }}
            </li>
          </ul>
        </div>

        <!-- Region/City input -->
        <div
          v-if="
            filters.location.type !== 'country' &&
            filters.location.type !== 'continents'
          "
        >
          <label :for="`${filters.location.type}-input`" class="sr-only">
            Enter a {{ filters.location.type }}
          </label>
          <input
            :id="`${filters.location.type}-input`"
            v-model="filters.location.search"
            type="text"
            :placeholder="
              filters.location.type === 'region'
                ? 'Enter a region'
                : 'Enter a city'
            "
            class="border border-grey-light rounded-lg p-2 w-full text-sm bg-background text-secondary mb-2"
            @input="restrictToLetters($event)"
          />
        </div>

        <!-- Continent options -->
        <div
          v-if="filters.location.type === 'continents'"
          id="continent-options"
        >
          <SwitchesCheckboxesSM
            :options="continentsOptions"
            v-model="filters.location.continents"
            aria-label="Select continents"
          />
        </div>
      </fieldset>

      <!-- Type of PL -->
      <fieldset class="py-4 border-b border-status-info">
        <legend class="text-primary font-semibold text-lg pt-4">
          Type of PL
        </legend>
        <SwitchesCheckboxesSM
          class="mt-0.5"
          :options="plTypeOptions"
          v-model="filters.plType"
          aria-label="Select PL types"
        />
      </fieldset>

      <!-- Transportation -->
      <fieldset class="py-4 border-b border-status-info">
        <legend class="text-primary font-semibold text-lg pt-4">
          Transportation
        </legend>
        <SwitchesCheckboxesSM
          class="mt-0.5"
          :options="transportationOptions"
          v-model="filters.transportation"
          aria-label="Select transportation options"
        />
      </fieldset>

      <!-- Storage Options -->
      <fieldset class="py-4 border-b border-status-info">
        <legend class="text-primary font-semibold text-lg pt-4">
          Storage Options
        </legend>
        <SwitchesCheckboxesSM
          class="mt-0.5"
          :options="storageOptions"
          v-model="filters.storageAndInventory"
          aria-label="Select storage options"
        />
      </fieldset>

      <!-- Sector/Industry -->
      <fieldset class="py-4 border-b border-status-info">
        <legend class="text-primary font-semibold text-lg pt-4">
          Sector/Industry
        </legend>
        <SwitchesCheckboxesSM
          class="mt-0.5"
          :options="industryOptions"
          v-model="filters.sectorIndustry"
          aria-label="Select industry sectors"
        />
      </fieldset>

      <!-- Contract Options -->
      <fieldset class="py-4 border-b border-status-info">
        <legend class="text-primary font-semibold text-lg pt-4">
          Contract Options
        </legend>
        <SwitchesCheckboxesSM
          class="mt-0.5"
          :options="contractOptions"
          v-model="filters.contract"
          aria-label="Select contract options"
        />
      </fieldset>

      <!-- Orders Management Options -->
      <fieldset class="py-4 mb-3">
        <legend class="text-primary font-semibold text-lg pt-4">
          Orders Management Options
        </legend>
        <SwitchesCheckboxesSM
          class="mt-0.5"
          :options="ordersManagementOptions"
          v-model="filters.ordersManagement"
          aria-label="Select orders management options"
        />
      </fieldset>

      <!-- Apply Button -->
      <div class="flex justify-center">
        <button
          type="submit"
          class="bg-brand-darkblue text-white border border-brand-yellow hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowfont-semibold py-2 px-4 rounded shadow-lg"
        >
          Apply Filters
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import SwitchesCheckboxesSM from "@/components/Search/Services/SwitchesCheboxesSM.vue";
import countries from "@/assets/location/countries/countries.json";

export default {
  name: "ServicesSearchFilters",
  components: {
    SwitchesCheckboxesSM,
  },
  data() {
    return {
      filters: {
        plType: {},
        location: {
          search: "",
          type: "country",
          internationalCoverage: false,
          continents: {},
        },
        transportation: {},
        storageAndInventory: {},
        sectorIndustry: {},
        contract: {},
        ordersManagement: {},
      },
      continentsOptions: [
        { name: "europe", label: "Europe", value: true },
        { name: "northAmerica", label: "North America", value: true },
        { name: "asia", label: "Asia", value: true },
        { name: "southAmerica", label: "South America", value: true },
        { name: "oceania", label: "Oceania", value: true },
        { name: "africa", label: "Africa", value: true },
      ],
      plTypeOptions: [
        { name: "pl2", label: "2PL", value: true },
        { name: "pl3", label: "3PL", value: true },
        { name: "pl4", label: "4PL", value: true },
        { name: "pl5", label: "5PL", value: true },
      ],
      transportationOptions: [
        { name: "aerialTransportation", label: "Aerial", value: false },
        { name: "groundTransportation", label: "Ground", value: false },
        { name: "maritimeTransportation", label: "Maritime", value: false },
        { name: "lastMile", label: "Last Mile", value: false },
        { name: "multimodalTransportation", label: "Multimodal", value: false },
      ],
      storageOptions: [
        { name: "dry", label: "Dry", value: false },
        { name: "refrigerated", label: "Refrigerated", value: false },
        { name: "freezer", label: "Freezer", value: false },
        { name: "dangerousGoods", label: "Dangerous Goods", value: false },
      ],
      industryOptions: [
        { name: "retail", label: "Retail", value: false },
        { name: "foodsAndDrinks", label: "Foods & Drinks", value: false },
        { name: "pharma", label: "Pharma", value: false },
        { name: "automotive", label: "Automotive", value: false },
        { name: "technology", label: "Technology", value: false },
        { name: "clothes", label: "Clothes", value: false },
      ],
      contractOptions: [
        { name: "shortTermOperations", label: "Short-Term", value: false },
        { name: "longTermOperations", label: "Long-Term", value: false },
        { name: "ocasionalOperations", label: "Occasional", value: false },
      ],
      ordersManagementOptions: [
        { name: "picking", label: "Picking", value: false },
        { name: "packing", label: "Packing", value: false },
        { name: "labeling", label: "Labeling", value: false },
      ],
      countrySearchQuery: this.initialCountry,
      filteredCountries: [],
      selectedCountryIndex: -1,
    };
  },
  computed: {
    country() {
      return this.countrySearchQuery;
    },
  },
  methods: {
    updateLocationType() {
      if (this.filters.location.internationalCoverage) {
        this.filters.location.type = "continents";
      } else if (this.filters.location.type === "continents") {
        this.filters.location.type = "country";
      }
    },
    applyFilters() {
      console.log(this.filters);
      this.$emit("apply-filters", this.filters);
    },

    restrictToLetters(event) {
      const input = event.target;
      const newValue = input.value.replace(/[^a-zA-Z\s]/g, "");
      input.value = newValue;
      this.countrySearchQuery = newValue;
    },

    /* Country methods */
    filterCountries() {
      if (this.countrySearchQuery.length > 0) {
        this.filteredCountries = countries.filter((country) =>
          country
            .toLowerCase()
            .startsWith(this.countrySearchQuery.toLowerCase()),
        );
        this.selectedCountryIndex = -1;
      } else {
        this.filteredCountries = [];
      }
    },
    selectCountry(country) {
      this.countrySearchQuery = country;
      this.filteredCountries = [];
      this.selectedCountryIndex = -1;
      this.$emit("country-selected", country);
    },
    navigateCountryList(event) {
      if (this.filteredCountries.length > 0) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex + 1) % this.filteredCountries.length;
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex - 1 + this.filteredCountries.length) %
            this.filteredCountries.length;
        } else if (event.key === "Enter" && this.selectedCountryIndex >= 0) {
          event.preventDefault();
          this.selectCountry(this.filteredCountries[this.selectedCountryIndex]);
        }
      }
    },
  },
};
</script>

<style scoped>
.switch {
  list-style: none;
}

.switch label {
  cursor: pointer;
}
[type="checkbox"] {
  position: absolute;
  left: -9999px;
}
.switch {
  max-width: 500px;
  width: 97.75%;
  border-radius: 5px;
}

.switches li {
  position: relative;
  counter-increment: switchCounter;
}

.switch li:not(:last-child) {
  border-bottom: 1px solid var(--color-text-secondary);
  border-style: dashed;
}

.switch li::before {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  font-size: 1rem;
  font-weight: bold;
  color: #ff4b77;
}

.switch label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.switch span:last-child {
  position: relative;
  width: 42px;
  height: 22px;
  border-radius: 15px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: #808080;
  transition: all 0.3s;
}

.switch span:last-child::before,
.switch span:last-child::after {
  content: "";
  position: absolute;
}

.switch span:last-child::before {
  left: 1px;
  top: 1px;
  width: 20px;
  height: 20px;
  background: #e8e9ed;
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.3s;
}

.switch span:last-child::after {
  top: 50%;
  right: 8px;
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  background-size: 10px 10px;
}

.switch [type="checkbox"]:checked + label span:last-child {
  background: #00d084;
}

.switch [type="checkbox"]:checked + label span:last-child::before {
  transform: translateX(20px);
}

.switch [type="checkbox"]:checked + label span:last-child::after {
  width: 10px;
  height: 10px;
  right: auto;
  left: 8px;
  background-image: url(../../../assets/images/checkmark-switcher.svg);
  background-size: 10px 10px;
}
/* Country Input Styles */
#country-list {
  position: absolute;
  top: calc(100% - 0.9rem);
  left: 0;
  width: 100%;
  z-index: 999;
  max-height: 333px;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style-type: none;
}

#country-list li {
  padding: 0.67rem 1rem;
  background: white;
}

#country-list li:hover,
#country-list .selected {
  background-color: #004aad;
  color: #efb615;
  cursor: pointer;
}
#country-list li:first-child {
  border-radius: 8px 8px 0 0;
}
#country-list li:last-child {
  border-radius: 0 0 8px 8px;
}
#country-list li:first-child:last-child {
  border-radius: 8px;
}
</style>
