<template>
  <div class="min-md:p-6 md:p-2 bg-surface text-primary rounded-lg shadow-lg">
    <h1 class="text-2xl font-bold flex items-center space-x-2 mb-3">
      <i class="fa-solid fa-gears"></i> Services Management
    </h1>
    <div
      v-if="services.length"
      class="grid grid-cols-1 min-md:grid-cols-2 min-lg:grid-cols-3 gap-6"
    >
      <!-- Service Card -->
      <div
        v-for="service in services"
        :key="service._id"
        @click="viewDetails(service)"
        class="border border-grey-light rounded-lg shadow-md overflow-hidden bg-background hover:cursor-pointer"
      >
        <!-- Image -->
        <div class="h-48 bg-grey-light flex items-center justify-center">
          <img
            v-if="service.imageUrl"
            :src="service.imageUrl"
            alt="Service Image"
            class="h-full w-full object-cover"
          />
          <div v-else class="text-grey">No Image Available</div>
        </div>

        <!-- Content -->
        <div class="p-4">
          <!-- Location -->
          <div class="text-brand-yellow font-bold text-lg">
            {{ service.location.city }}, {{ service.location.country }}
          </div>
          <!-- Pallet Positions -->
          <div class="text-grey-light text-sm">
            Pallet Positions:
            <span class="font-semibold">{{
              service.dimensions.palletPositions
            }}</span>
          </div>
          <!-- PL Types -->
          <div class="mt-2">
            <span
              v-for="type in service.plType"
              :key="type"
              class="inline-block bg-brand-darkblue text-primary text-xs font-semibold rounded-full px-2 py-1 mr-2"
            >
              {{ type }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center text-lg text-grey mt-10">
      No services registered for this account.
    </div>
    <!-- Register New Service Button -->
    <router-link
      to="/service-registry"
      class="block mt-6 border border-brand-darkblue text-center bg-brand-darkblue text-brand-yellow font-semibold py-3 px-6 rounded-lg hover:bg-brand-yellow hover:text-brand-darkblue w-fit"
    >
      <i class="fa-solid fa-plus"></i> Register New Service
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import apiClient from "@/api";

export default {
  data() {
    return {
      services: [], // Stores the services fetched from the server
      fallbackImage: require("@/assets/images/service-icon.png"), // Fallback image path
    };
  },
  computed: {
    ...mapGetters(["user"]), // Fetch the logged-in user's details
  },
  methods: {
    ...mapActions(["fetchUser"]), // Vuex action to fetch the user's information

    // Fetches services and preloads their image URLs
    async fetchServices() {
      if (!this.user) {
        console.error("No user found. Please log in.");
        return;
      }
      try {
        // API call to fetch services for the logged-in user
        const response = await apiClient.get(`/services/user/${this.user._id}`);
        const services = response.data;

        // Fetch image URLs for each service
        for (const service of services) {
          service.imageUrl = await this.getSpaceImage(service);
        }

        this.services = services; // Update the reactive `services` property
      } catch (error) {
        console.error("Error loading services:", error.message);
      }
    },

    // Retrieves the image URL for a given service
    async getSpaceImage(service) {
      try {
        const imageId = service?.multimedia?.images?.[0]; // Get the first image ID

        if (!imageId) {
          console.warn("No image ID found. Using fallback image.");
          return this.fallbackImage;
        }

        const response = await apiClient.get(`/images/${imageId}`, {
          responseType: "blob",
        });

        // Validate response status and return blob URL
        if (response.status >= 200 && response.status < 300) {
          return URL.createObjectURL(response.data); // Convert blob to URL
        } else {
          console.warn("Failed to fetch service image. Using fallback image.");
          return this.fallbackImage;
        }
      } catch (error) {
        console.error(
          "Error fetching service image:",
          error?.toString() || error,
        );
        return this.fallbackImage;
      }
    },
    viewDetails(service) {
      this.$router.push({
        name: "MyServiceDetails",
        params: { serviceId: service._id },
      });
    },
  },
  mounted() {
    this.fetchServices(); // Fetch services when the component is mounted
  },
};
</script>

<style scoped></style>
