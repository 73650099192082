<template>
  <nav
    class="w-full text-primary p-6 rounded-lg bg-surface"
    aria-label="Account navigation"
  >
    <!-- Greeting Section -->
    <h2
      class="text-xl font-semibold mb-3 text-center h-8 truncate"
      id="account-greeting"
    >
      Welcome back,
      <span class="text-brand-yellow">{{ username }}</span
      >!
    </h2>
    <div class="bg-primary h-0.5 w-full mb-4" aria-hidden="true"></div>

    <!-- Navigation Buttons -->
    <ul role="menubar" aria-labelledby="account-greeting">
      <li v-for="button in buttons" :key="button.section" role="none">
        <button
          @click="handleButtonClick(button)"
          @keydown.enter="handleButtonClick(button)"
          @keydown.space="handleButtonClick(button)"
          :class="[
            'flex w-full items-center mt-1 py-3 px-4 rounded-lg cursor-pointer transition-colors',
            currentSection === button.section
              ? 'bg-brand-yellow text-brand-darkblue border-brand-darkblue font-semibold'
              : 'text-primary hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowhover:bg-brand-darkblue',
          ]"
          :aria-current="currentSection === button.section ? 'page' : null"
          role="menuitem"
          tabindex="0"
        >
          <i :class="button.icon + ' mr-2'" aria-hidden="true"></i>
          {{ button.text }}
        </button>
        <div
          v-if="button.separator"
          class="border-t border-gray-300 my-2"
          role="separator"
          aria-hidden="true"
        ></div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    currentSection: String,
  },
  data() {
    return {
      buttons: [
        {
          section: "UserDetails",
          text: "User Details",
          icon: "fa-solid fa-user",
          separator: false,
        },
        {
          section: "MessageCenter",
          text: "Message Center",
          icon: "fa-solid fa-mailbox-flag-up",
          separator: true,
        },
        {
          section: "MyFavourites",
          text: "My Favourites",
          icon: "fa-solid fa-heart",
          separator: false,
        },
        {
          section: "SavedSearches",
          text: "Saved Searches",
          icon: "fa-solid fa-user-magnifying-glass",
          separator: true,
        },
        {
          section: "SpacesManagement",
          text: "Spaces Management",
          icon: "fa-solid fa-shop-lock",
          separator: false,
        },
        {
          section: "ServicesManagement",
          text: "Services Management",
          icon: "fa-solid fa-gears",
          separator: false,
        },
      ],
    };
  },
  computed: {
    // Correctly map the user state from the "auth" module
    ...mapState("auth", {
      user: (state) => state.user,
    }),
    username() {
      return this.user ? this.user.name : "Guest";
    },
  },
  methods: {
    handleButtonClick(button) {
      this.$emit("section-change", button.section);
    },
  },
};
</script>

<style scoped></style>
