<template>
  <div class="theme-transition">
    <LandingHeader />
    <main>
      <HeroSection />

      <div class="bg-surfaceSaturated">
        <PlatformSection
          image-src="/images/transport-logistics-concept-2-1.png"
          image-alt="Logistics concept"
          title="NEED TO BOOST YOUR LOGISTICS?"
          description="Find the logistics provider that best suits your needs and cut your logistics costs"
          cta-text="Check our Directory"
          cta-link="/services-search"
          rounded
        />

        <PlatformSection
          image-src="/images/interior-large-distribution-warehouse-with-shelves-stacked-with-.png"
          image-alt="Warehouse interior"
          title="LOOKING FOR A LOGISTICS WAREHOUSE?"
          description="Access the largest platform of logistics facilities and find the perfect warehouse for your business."
          cta-text="Spaces Searcher"
          cta-link="#"
          reverse
          class="pb-20"
          disabled
        />
      </div>

      <div class="bg-background">
        <MissionSection />
      </div>

      <div class="bg-surface">
        <ClientsSection />
      </div>

      <div class="bg-surfaceSaturated">
        <BlogSection />
      </div>
    </main>
    <div class="bg-background">
      <LandingFooter />
    </div>
  </div>
</template>

<script>
import LandingHeader from "./LandingHeader.vue";
import LandingFooter from "./LandingFooter.vue";
import HeroSection from "./HeroSection.vue";
import PlatformSection from "./PlatformSection.vue";
import ClientsSection from "./ClientsSection.vue";
import MissionSection from "./MissionSection.vue";
import BlogSection from "./BlogSection.vue";

export default {
  components: {
    LandingHeader,
    LandingFooter,
    HeroSection,
    PlatformSection,
    ClientsSection,
    MissionSection,
    BlogSection,
  },
};
</script>

<style scoped></style>
