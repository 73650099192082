import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Import global styles
import "./styles/base.css";
import "@fortawesome/fontawesome-free/css/all.css";

// Syncfusion license registration
/*import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(process.env.VUE_APP_SYNCFUSION_LICENSE);
*/
// Set up router navigation guards
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Biglo";
  next();
});

// Create and mount Vue application
const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
