<template>
  <div
    class="bg-surfaceSaturatedLight text-primary md:p-4 min-md:p-8 mx-auto rounded-2xl max-w-lg"
  >
    <h1
      class="text-2xl font-semibold text-center mb-8 flex items-center justify-center space-x-2"
    >
      <i class="fa-solid fa-user-check mr-2" aria-hidden="true"></i>
      <span>User Verification</span>
    </h1>

    <!-- Success Message with Countdown and Redirect Options -->
    <div
      v-if="!loading && success"
      class="text-status-success flex flex-col space-y-2"
      aria-live="assertive"
    >
      <p class="mb-2 mx-auto max-w-min">
        <i class="fa fa-check-circle fa-2xl"></i>
      </p>
      <p class="text-center">Your account has been verified successfully!</p>
      <div class="text-secondary text-center">
        <p class="text-status-info" aria-live="polite">
          Redirecting in {{ countdown }} seconds...
        </p>
        <p>
          If not redirected, you can go to
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline"
            v-if="!isAuthenticated"
            to="/log-in"
            >log-in</router-link
          >
          <span v-if="!isAuthenticated"> or </span>
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline"
            v-if="isAuthenticated"
            to="/your-account"
            >your-account</router-link
          >
          <span v-if="isAuthenticated"> or </span>
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline"
            to="/"
            >return home</router-link
          >.
        </p>
      </div>
    </div>

    <!-- Error Message -->
    <div
      v-if="!loading && error"
      class="text-status-error flex flex-col space-y-2"
      aria-live="assertive"
    >
      <p class="mb-2 mx-auto max-w-min">
        <i class="fa fa-exclamation-triangle fa-2xl"></i>
      </p>
      <p class="text-center">Verification failed: {{ errorMessage }}</p>
      <div class="text-secondary text-center">
        <p>
          Try again later,
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline"
            v-if="!isAuthenticated"
            to="/log-in"
            >log-in</router-link
          >
          <span v-if="!isAuthenticated"> or </span>
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline"
            v-if="isAuthenticated"
            to="/your-account"
            >your-account</router-link
          >
          <span v-if="isAuthenticated"> or </span>
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline"
            to="/"
            >return home</router-link
          >.
        </p>
        <p>
          If the problem persists, please, contact our
          <a
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline"
            target="_blank"
            href="mailto:biglodevelop@gmail.com?subject=User Verification Error"
            >support</a
          >.
        </p>
      </div>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="text-center">
      <p>Verifying your email...</p>
    </div>

    <!-- Notification -->
    <NotificationCustom
      v-if="notification.message"
      :type="notification.type"
      :message="notification.message"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import apiClient from "@/api";
import NotificationCustom from "@/components/common/NotificationCustom.vue";

export default {
  components: { NotificationCustom },
  data() {
    return {
      loading: true,
      success: false,
      error: false,
      errorMessage: "",
      notification: {
        message: "",
        type: "",
      },
      countdown: 10,
      redirectInterval: null,
    };
  },
  computed: {
    // Computed property to determine the redirect path based on authentication status
    redirectPath() {
      return this.isAuthenticated ? "/your-account" : "/log-in";
    },
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  created() {
    this.verifyEmail();
  },
  beforeUnmount() {
    clearInterval(this.redirectInterval);
  },
  methods: {
    async verifyEmail() {
      const token = this.$route.query.token;
      if (!token) {
        this.errorMessage = "Invalid verification link.";
        this.error = true;
        this.loading = false;
        this.showNotification("error", this.errorMessage);
        return;
      }

      try {
        // Send POST request with token in the request body
        const response = await apiClient.post("/auth/verify-email", {
          token: token,
        });

        // Handle success case
        if (response.status >= 200 && response.status < 300) {
          this.success = true;
          this.showNotification("success", "Email verified successfully!");
          this.startRedirectCountdown();
        } else {
          throw new Error(response.data.error || "Verification failed.");
        }
      } catch (err) {
        // Handle error cases
        this.errorMessage =
          err.response?.data?.error || err.message || "Verification failed.";
        this.error = true;
        this.showNotification("error", this.errorMessage);
      } finally {
        this.loading = false;
      }
    },
    startRedirectCountdown() {
      this.redirectInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.redirectInterval);
          this.$router.push(this.redirectPath);
        }
      }, 1000);
    },

    // Method to show a custom notification
    showNotification(type, message) {
      this.notification = { type, message };
    },
  },
};
</script>

<style scoped></style>
