<template>
  <footer
    class="bg-brand-darkblue-navy text-white theme-transition rounded-t-3xl border-t border-status-info"
  >
    <div class="container mx-auto px-4 pt-10 pb-6">
      <div
        class="flex flex-col min-md:flex-row items-start justify-between gap-8"
      >
        <!-- Brand Logo and Name -->
        <router-link
          class="!duration-0 flex items-center lg:mb-0 text-white hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowtransition-colors lg:mx-auto min-lg:my-auto"
          to="/"
          aria-label="Home"
        >
          <img
            v-once
            src="/images/biglo_logo.png"
            alt="Biglo Logo"
            width="40"
            height="40"
            loading="lazy"
          />
          <div v-once class="text-4xl font-medium ml-2">Biglo</div>
        </router-link>

        <!-- Footer Links -->
        <div
          class="grid grid-cols-1 min-sm:grid-cols-2 min-md:grid-cols-4 gap-8 w-full max-w-4xl text-center"
        >
          <!-- About Us Section -->
          <section aria-labelledby="about-us-heading">
            <h2
              id="about-us-heading"
              class="text-lg text-brand-yellow-light font-medium mb-4"
            >
              About Us
            </h2>
            <ul class="space-y-2">
              <li>
                <router-link
                  to="/our-mission"
                  class="hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowtransition-colors"
                >
                  Our Mission
                </router-link>
              </li>
              <li>
                <router-link
                  to="#"
                  @click.prevent
                  aria-disabled="true"
                  class="text-[#c7c7c7] cursor-not-allowed transition-colors"
                >
                  Our Partners
                </router-link>
              </li>
            </ul>
          </section>

          <!-- Resources Section -->
          <section aria-labelledby="resources-heading">
            <h2
              id="resources-heading"
              class="text-lg text-brand-yellow-light font-medium mb-4"
            >
              Resources
            </h2>
            <ul class="space-y-2">
              <li>
                <router-link
                  to="#"
                  @click.prevent
                  aria-disabled="true"
                  class="text-[#c7c7c7] cursor-not-allowed transition-colors"
                >
                  FAQ
                </router-link>
              </li>
              <li>
                <router-link
                  to="/blog"
                  class="hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowtransition-colors"
                >
                  Blog
                </router-link>
              </li>
            </ul>
          </section>

          <!-- Biglo Section -->
          <section aria-labelledby="biglo-heading">
            <h2
              id="biglo-heading"
              class="text-lg text-brand-yellow-light font-medium mb-4"
            >
              Biglo
            </h2>
            <ul class="space-y-2">
              <li>
                <router-link
                  to="#"
                  @click.prevent
                  aria-disabled="true"
                  class="text-[#c7c7c7] cursor-not-allowed transition-colors"
                >
                  Terms and Conditions
                </router-link>
              </li>
              <li>
                <router-link
                  to="#"
                  @click.prevent
                  aria-disabled="true"
                  class="text-[#c7c7c7] cursor-not-allowed transition-colors"
                >
                  Cookies' Policy
                </router-link>
              </li>
              <li>
                <router-link
                  to="#"
                  @click.prevent
                  aria-disabled="true"
                  class="text-[#c7c7c7] cursor-not-allowed transition-colors"
                >
                  Privacy Policy
                </router-link>
              </li>
            </ul>
          </section>

          <!-- Social Links -->
          <section aria-labelledby="social-heading">
            <h2
              id="social-heading"
              class="text-lg text-brand-yellow-light font-medium mb-4"
            >
              Follow Us
            </h2>
            <div class="flex flex-col space-y-2 items-center">
              <!-- LinkedIn -->
              <a
                href="https://www.linkedin.com/company/biglosolutions/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-white hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellow!transition-none !duration-0"
                aria-label="LinkedIn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 fill-current !transition-none !duration-0"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                  />
                </svg>
              </a>

              <!-- YouTube -->
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                class="text-white hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellow!transition-none !duration-0"
                aria-label="YouTube"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 fill-current !transition-none !duration-0"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"
                  />
                </svg>
              </a>
            </div>
          </section>
        </div>
      </div>

      <!-- Divider -->
      <div class="border-t border-status-info mb-6 mt-12"></div>

      <!-- Copyright -->
      <div class="text-center text-sm text-white">
        Copyright © 2025 Biglo Solutions
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "CommonFooter",
};
</script>

<style scoped>
.social-icon {
  background-color: transparent;
  transition:
    background-color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.social-icon svg {
  fill: white;
  transition: fill 0.3s ease-in-out;
}

.social-icon:hover svg {
  fill: #efb615; /* Yellow color on hover */
}
</style>
