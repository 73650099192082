<template>
  <CommonHeader />
  <RegisterSpaceForm />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import RegisterSpaceForm from "@/components/Spaces/RegisterSpaceForm.vue";

export default {
  name: "SpaceRegistry",
  metaInfo: {
    title: "Space Registry | Biglo",
  },
  components: {
    CommonHeader,
    RegisterSpaceForm,
  },
};
</script>

<style scoped></style>
