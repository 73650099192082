import apiClient from "@/api";
import * as types from "../types";

export default {
  async initializeAuth({ commit }) {
    try {
      // Verify token validity with backend
      const response = await apiClient.get("/auth/validate-session");
      const { user, tokenExpiration } = response.data;

      // Check if the token is expired
      if (tokenExpiration < Date.now()) {
        commit(types.LOGOUT);
        return;
      }

      // Commit the user and token expiration to the store
      commit(types.SET_USER, { user, tokenExpiration });
    } catch (error) {
      commit(types.LOGOUT);
    }
  },

  async fetchCsrfToken({ commit }) {
    try {
      const response = await apiClient.get("/csrf-token");

      commit(types.SET_CSRF_TOKEN, response.data.csrfToken);
      return response.data.csrfToken; // Return CSRF token for immediate use if needed
    } catch (error) {
      console.error("Failed to fetch CSRF token:", {
        error: error.message,
        response: error.response,
      });
      throw new Error("Failed to fetch CSRF token. Please try again.");
    }
  },

  async login({ commit }, { email, password }) {
    try {
      const response = await apiClient.post("/auth/login", {
        email,
        password,
      });

      commit(types.SET_USER, {
        user: response.data.user,
      });
    } catch (error) {
      console.error("Login failed:", {
        error: error.message,
        response: error.response,
      });

      if (error.response?.status === 401) {
        throw new Error("Invalid email or password.");
      }
      throw new Error("Login failed. Please try again.");
    }
  },

  async register({ commit }, userData) {
    try {
      const response = await apiClient.post("/auth/register", userData);
      commit(types.SET_USER, { user: response.data.user });

      // Only fetch logo if it exists
      if (response.data.user.logo) {
        const logoBlob = await apiClient
          .get(`/images/${response.data.user.logo}`)
          .then((res) => res.blob());
        commit(types.SET_USER_LOGO_BLOB, logoBlob);
      }

      return response.data; // Return the response for further handling
    } catch (error) {
      if (error.response?.status === 409) {
        throw new Error("Email already exists.");
      }
      throw new Error("Registration failed. Please try again.");
    }
  },

  async logout({ commit }) {
    try {
      await apiClient.post("/auth/logout");
    } catch (error) {
      console.error("Logout error:", error);
    }
    commit(types.LOGOUT);
  },

  async fetchUser({ commit, state }, userId) {
    // Avoid fetching if the user is already in the state
    if (state.user && state.user._id === userId && state.userLogoBlob) {
      return;
    }

    commit(types.SET_LOADING, true); // Start loading
    try {
      const response = await apiClient.get(`/users/${userId}`);

      // Update the state with the fetched user data
      commit(types.SET_USER, { user: response.data.user });

      // Fetch the user's logo if it exists
      if (response.data.user.logo) {
        const logoBlob = await apiClient
          .get(`/images/${response.data.user.logo}`)
          .then((res) => res.blob());
        commit(types.SET_USER_LOGO_BLOB, logoBlob);
      }
    } catch (error) {
      console.error("Error fetching user:", {
        error: error.message,
        response: error.response,
      });
      throw new Error("Failed to fetch user data.");
    } finally {
      commit(types.SET_LOADING, false); // Stop loading
    }
  },
};
