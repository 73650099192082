<template>
  <div class="relative mx-auto multimedia-step p-4">
    <span class="block text-2xl font-semibold text-brand-darkblue text-center">
      <i class="fa-solid fa-photo-film"></i>
      11th Step: Multimedia
    </span>
    <span
      class="block mt-2 text-base font-normal text-brand-darkblue text-center"
    >
      (It's optional)
    </span>
    <div
      class="input-container mx-auto mt-4 flex flex-col relative max-w-md sm:max-w-full"
    >
      <label for="images" class="text-brand-darkblue font-medium text-lg mb-4">
        <i class="fa-solid fa-image mr-1"></i>
        Images
      </label>
      <DropZone id="images" @file-uploaded="handleFileUploaded" />
    </div>
    <div class="button-container relative mx-auto w-fit">
      <button
        class="next-step relative mx-auto rounded-lg mt-5 px-4 py-3 bg-brand-darkblue text-brand-yellow font-medium border border-brand-darkblue hover:bg-brand-yellow hover:text-brand-darkblue"
        @click="nextStep"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
import DropZone from "@/components/common/Dropzone.vue";

export default {
  name: "MultimediaStep",
  components: { DropZone },
  data() {
    return {
      multimedia: {
        images: [], // Stores the image fileId(s)
      },
    };
  },
  methods: {
    handleFileUploaded(fileId) {
      this.multimedia.images = [fileId]; // Allow only one image for now
      this.$emit("update:multimedia", this.multimedia); // Emit to parent component
    },
    nextStep() {
      this.$emit("change-step", "description"); // Proceed to the next step
    },
  },
};
</script>

<style scoped></style>
