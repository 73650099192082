<template>
  <header class="header bg-transparent" :class="{ scrolled: isScrolled }">
    <div class="header-container">
      <div class="header-content">
        <router-link
          to="/"
          id="logo"
          class="flex items-center text-white hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowmr-8 select-none"
          aria-label="Go to homepage"
        >
          <img
            v-once
            class="logoimg"
            src="/images/biglo_logo.png"
            alt="Biglo Logo"
            width="40"
            height="40"
            loading="eager"
          />
          <h1 id="logo_name" class="pl-2 text-4xl font-medium">Biglo</h1>
        </router-link>

        <input type="checkbox" id="check" class="hidden-checkbox" />
        <label for="check" class="checkbtn" aria-label="Toggle navigation menu">
          <i class="fas fa-bars"></i>
        </label>

        <ul class="nav-links">
          <!-- Services and Spaces Group -->
          <li class="link-group" aria-label="Main Navigation">
            <ul class="link-subgroup">
              <li class="nav-item">
                <router-link to="/services-search" aria-label="Services">
                  <i class="fa-solid fa-folder"></i>
                  Services
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="#"
                  class="!text-[#c7c7c7] hover:cursor-not-allowed"
                  aria-disabled="true"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                  Spaces
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Separator -->
          <li class="separator" aria-hidden="true"></li>

          <!-- Our Mission and Blog Group -->
          <li class="link-group" aria-label="About Us">
            <ul class="link-subgroup">
              <li class="nav-item">
                <router-link to="/our-mission" aria-label="Our Mission">
                  <i class="fa-solid fa-lightbulb"></i>
                  Our Mission
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/blog" aria-label="Blog">
                  <i class="fa-solid fa-book-open"></i>
                  Blog
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Separator -->
          <li class="separator" aria-hidden="true"></li>

          <!-- Sign Up/Register and Log In/Your Account Group -->
          <li class="link-group" aria-label="Account">
            <ul class="link-subgroup">
              <li class="nav-item">
                <router-link
                  v-if="!isAuthenticated"
                  to="/sign-up"
                  class="register"
                  aria-label="Sign Up"
                >
                  <i class="fa-solid fa-user-plus"></i>
                  Sign Up
                </router-link>
                <router-link
                  v-else
                  to="/register-space-or-service"
                  class="register"
                  aria-label="Register space or service"
                >
                  <i class="fa-solid fa-thumbtack"></i>
                  Register space/service!
                </router-link>
              </li>
              <li class="nav-item login-item">
                <router-link
                  v-if="!isAuthenticated"
                  to="/log-in"
                  aria-label="Log In"
                >
                  <i class="fa-solid fa-user"></i>
                  Log In
                </router-link>
                <router-link
                  v-else-if="!isOnYourAccount"
                  to="/your-account"
                  aria-label="Your Account"
                >
                  <i class="fa-solid fa-circle-user"></i>
                  Your Account
                </router-link>
                <button
                  v-else
                  @click="handleLogout"
                  class="logout"
                  aria-label="Log Out"
                >
                  <i class="fa-solid fa-arrow-right-from-bracket"></i>
                  Log Out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isScrolled: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    isOnYourAccount() {
      return this.$route.path === "/your-account";
    },
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout();
      this.$router.push("/");
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
/* Header container */
.header-container {
  width: 100%;
  background: transparent;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.5s ease-out;
}

.header.scrolled {
  background-color: #004aad;
}

.header-content {
  max-width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 86px;
  padding: 16px 0;
  transition: all 0.5s ease-out;
}
#logo {
  transition: none !important;
}
/* Navigation */
.nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  max-width: max(67%, 1000px); /* Limits growth */
}

/* Groups of links */
.link-group {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
}

.link-subgroup {
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
}

/* Separator */
.separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 32px;
  width: 1px;
  background-color: white;
}

/* Navigation items */
.nav-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: white;
  transition: none !important;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}
.nav-item i {
  margin-right: 0.25rem;
  transition: none !important;
}
.nav-item a:hover,
.nav-item a:focus,
.nav-item a:focus-visible,
.nav-item button:hover,
.nav-item button:focus,
.nav-item button:focus-visible {
  color: #efb615;
  transition: all 0.2s ease-out !important;
}

/* Hidden checkbox */
.hidden-checkbox {
  display: none;
}

.checkbtn {
  cursor: pointer;
  display: none;
  font-size: 24px;
}

@media (max-width: 1061px) {
  .checkbtn {
    display: block;
    color: white;
  }

  .nav-links {
    position: fixed;
    width: 100vw;
    height: calc(100dvh - 86px);
    top: 86px;
    right: -100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: #061656;
    transition: right 0.5s ease-out;
    justify-content: start;
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 1rem;
  }

  #check:checked ~ .nav-links {
    right: 0;
  }

  .link-group {
    flex-direction: column;
    align-items: center;
  }

  .link-subgroup {
    flex-direction: column;
    align-items: center;
  }

  .separator {
    width: 50%;
    height: 1px;
  }
}
</style>
