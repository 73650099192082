<template>
  <div
    class="bg-surfaceSaturatedLight text-primary md:p-4 min-md:p-8 mx-auto rounded-2xl max-w-lg"
  >
    <h1
      class="text-2xl font-semibold text-center mb-8 flex items-center justify-center space-x-2"
    >
      <i class="fa-solid fa-user mr-2" aria-hidden="true"></i>
      <span>User Login</span>
    </h1>
    <form id="login-form" @submit.prevent="handleSubmit">
      <label for="email" class="block font-medium mb-1 mt-4">E-mail</label>
      <input
        class="w-full bg-background border-primary py-3 px-4 border rounded-lg"
        type="email"
        placeholder="Enter your email"
        autocomplete="email"
        autocorrect="off"
        spellcheck="false"
        id="email"
        v-model="form.email"
        required
      />

      <div class="mt-4">
        <label for="password" class="block font-medium mb-1">Password</label>
        <div class="flex">
          <input
            :type="passwordFieldType"
            class="w-full bg-background border-primary py-3 px-4 border rounded-l-lg"
            name="password"
            placeholder="Enter your password"
            autocomplete="current-password"
            autocorrect="off"
            spellcheck="false"
            id="password"
            v-model="form.password"
            required
          />
          <button
            type="button"
            class="min-w-[50px] py-3 px-4 bg-brand-darkblue text-white rounded-r-lg border-r border-y border-l-0 border-brand-yellow br-0 hover:text-brand-yellow focus:text-brand-yellow transition-colors duration-200 ease-in-out"
            @click="togglePassword"
            :aria-label="
              passwordFieldType === 'password'
                ? 'Show password'
                : 'Hide password'
            "
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
      </div>

      <router-link
        to="/forgotten-password"
        class="block font-medium mt-6 text-brand-yellow-light hover:underline focus:underline hover:text-brand-yellow focus:text-brand-yellow transition-colors max-w-fit"
        >Forgot your password?</router-link
      >

      <button
        type="submit"
        class="w-full mt-10 py-3 px-4 bg-brand-darkblue text-white border border-brand-yellow rounded-lg font-medium text-lg hover:text-brand-yellow focus:text-brand-yellow transition-colors duration-200 ease-in-out"
      >
        Log-in
      </button>
      <p v-if="errorMessage" class="text-center mt-4 text-status-error">
        {{ errorMessage }}
      </p>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserLogin",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
      errorMessage: null,
    };
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async handleSubmit() {
      try {
        this.errorMessage = null;

        // Perform login
        await this.login({
          email: this.form.email,
          password: this.form.password,
        });

        // Redirect to the user's account page
        this.$router.push({ name: "your-account" });
      } catch (error) {
        this.errorMessage = error.message || "An error occurred during login.";
      }
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
  },
};
</script>

<style scoped>
input {
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus {
  border-color: #0080ff !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 128 255 / 0.75) !important;
}
</style>
