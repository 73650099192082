<template>
  <div class="map-search">
    <div class="header-container w-[100svw] shadow">
      <CommonHeader />
    </div>
    <CommonFooter />
  </div>
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import CommonFooter from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MapResultsPage",
  metaInfo: {
    title: "Map Search | Biglo",
  },
  components: {
    CommonHeader,
    CommonFooter,
  },
});
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: row;
}
</style>
