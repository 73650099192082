<template>
  <transition name="fade">
    <div
      v-if="visible"
      :class="['notification', type]"
      role="alert"
      :aria-live="type === 'error' ? 'assertive' : 'polite'"
      :aria-atomic="true"
      :aria-relevant="'all'"
      :aria-labelledby="'notification-message'"
      @mouseenter="pauseTimer"
      @mouseleave="resumeTimer"
    >
      <div class="notification-content">
        <div class="notification-icon">
          <i :class="['fa', iconClass]" aria-hidden="true"></i>
        </div>
        <span class="message font-medium" id="notification-message">{{
          message
        }}</span>
        <button
          @click="hide"
          @keydown.enter="hide"
          @keydown.space="hide"
          :class="type"
          aria-label="Close notification"
          :aria-describedby="'notification-message'"
          class="close-button"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div
        class="progress-bar"
        :style="{
          width: progressBarWidth + '%',
          animationPlayState: isPaused ? 'paused' : 'running',
        }"
        aria-hidden="true"
      ></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "NotificationCustom",
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) =>
        ["success", "info", "warning", "error"].includes(value),
    },
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 10000,
      validator: (value) => value > 0,
    },
  },
  data() {
    return {
      visible: true,
      progressBarWidth: 100,
      remainingTime: this.duration,
      timer: null,
      startTime: null,
      isPaused: false,
      pauseStartTime: null,
    };
  },
  computed: {
    iconClass() {
      const icons = {
        success: "fa-circle-check",
        info: "fa-circle-info",
        warning: "fa-circle-exclamation",
        error: "fa-triangle-exclamation",
      };
      return icons[this.type] || icons.info;
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.startTime = Date.now();
      this.timer = setInterval(this.updateTime, 100);
    },
    updateTime() {
      if (this.isPaused) return;

      const now = Date.now();
      const elapsed = now - this.startTime;
      this.remainingTime = Math.max(0, this.remainingTime - elapsed);
      this.startTime = now;

      if (this.remainingTime <= 0) {
        this.hide();
      }

      this.updateProgressBar();
    },
    updateProgressBar() {
      this.progressBarWidth = (this.remainingTime / this.duration) * 100;
    },
    pauseTimer() {
      if (!this.isPaused) {
        this.isPaused = true;
        this.pauseStartTime = Date.now();
        clearInterval(this.timer);
      }
    },
    resumeTimer() {
      if (this.isPaused) {
        this.isPaused = false;
        // Adjust remaining time by subtracting the pause duration
        const pauseDuration = Date.now() - this.pauseStartTime;
        this.startTime += pauseDuration;
        this.timer = setInterval(this.updateTime, 100);
      }
    },
    hide() {
      this.visible = false;
      clearInterval(this.timer);
      this.$emit("closed");
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.$emit("destroyed");
  },
};
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  color: white;
  font-family: Arial, sans-serif;
  font-size: 16px;
  min-width: 300px;
  max-width: 90vw;
  opacity: 0.95;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  will-change: opacity, transform;
  z-index: 1000;
}

.notification-content {
  display: flex;
  width: 100%;
  position: relative;
}

.notification-icon {
  margin-right: 12px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.message {
  flex-grow: 1;
  padding: 0 12px;
  text-align: left;
  line-height: 1.5;
}

.close-button {
  background: none;
  border: none;
  color: inherit;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0;
  position: absolute;
  top: 0px;
  right: 0px;
}

.notification button {
  color: rgba(255, 255, 255, 0.55);
}
.notification button:hover {
  color: #ffffff;
}
.notification.warning button {
  color: rgba(0, 0, 0, 0.5);
}
.notification.warning button:hover {
  color: black;
}

.notification.success button:hover {
  background-color: rgba(0, 209, 18, 0.2);
}

.notification.info button:hover {
  background-color: rgba(0, 128, 255, 0.2);
}

.notification.warning button:hover {
  background-color: rgba(247, 181, 0, 0.2);
}

.notification.error button:hover {
  background-color: rgba(230, 0, 0, 0.2);
}

.notification .progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  transition: width 0.1s linear;
  border-radius: 0 2.5px 2.5px 0;
}

/* Notification type styles */
.notification.success {
  background-color: #00d112;
  box-shadow: 0 4px 12px rgba(0, 209, 18, 0.25);
}

.notification.info {
  background-color: #0080ff;
  box-shadow: 0 4px 12px rgba(0, 128, 255, 0.25);
}

.notification.warning {
  background-color: #f7b500;
  color: black;
  box-shadow: 0 4px 12px rgba(247, 181, 0, 0.25);
}

.notification.error {
  background-color: #e60000;
  box-shadow: 0 4px 12px rgba(230, 0, 0, 0.25);
}

/* Transition effects */
.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translate(-50%, 20px);
}
</style>
