<template>
  <div
    class="bg-surfaceSaturated text-primary md:p-4 min-md:p-8 mx-auto rounded-2xl max-w-lg"
  >
    <h1
      class="text-2xl font-semibold text-center mb-8 flex items-center justify-center space-x-2"
    >
      <i class="fa-solid fa-unlock-keyhole mr-2" aria-hidden="true"></i>
      <span>Reset Password</span>
    </h1>
    <!-- Success Message with Countdown and Redirect Options -->
    <div
      v-if="!loading && success"
      class="text-status-success flex flex-col space-y-2 text-center"
      aria-live="assertive"
    >
      <p class="mb-2 mx-auto max-w-min">
        <i class="fa fa-check-circle fa-2xl"></i>
      </p>
      <p class="text-center">Your password has been reset successfully!</p>
      <div class="text-secondary text-center">
        <p class="text-status-info" aria-live="polite">
          Redirecting in {{ countdown }} seconds...
        </p>
        <p>
          If not redirected, you can go to
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline select-none"
            v-if="!isAuthenticated"
            to="/log-in"
            >log-in</router-link
          >
          <span v-if="!isAuthenticated"> or </span>
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline select-none"
            v-if="isAuthenticated"
            to="/your-account"
            >your-account</router-link
          >
          <span v-if="isAuthenticated"> or </span>
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline select-none"
            to="/"
            >return home</router-link
          >.
        </p>
      </div>
    </div>

    <!-- Error Message -->
    <div
      v-if="!loading && error"
      class="text-status-error flex flex-col space-y-2"
      aria-live="assertive"
    >
      <p class="mb-2 mx-auto max-w-min">
        <i class="fa fa-exclamation-triangle fa-2xl"></i>
      </p>
      <p class="text-center">Password reset failed: {{ serverMessage }}</p>
      <div class="text-secondary text-center">
        <p>
          Try again later,
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline select-none"
            v-if="!isAuthenticated"
            to="/log-in"
            >log-in</router-link
          >
          <span v-if="!isAuthenticated"> or </span>
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline select-none"
            v-if="isAuthenticated"
            to="/your-account"
            >your-account</router-link
          >
          <span v-if="isAuthenticated"> or </span>
          <router-link
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline select-none"
            to="/"
            >return home</router-link
          >.
        </p>
        <p>
          If the problem persists, please, contact our
          <a
            class="text-invertedAlt font-medium hover:text-inverted focus:text-inverted hover:underline focus:underline select-none"
            target="_blank"
            href="mailto:biglodevelop@gmail.com?subject=Password Reset Error"
            >support</a
          >.
        </p>
      </div>
    </div>

    <!-- Form for Resetting Password -->
    <form v-if="!success && !error" @submit.prevent="handlePasswordReset">
      <!-- New Password Field -->
      <div class="mt-6">
        <label for="new-password" class="block text-primary font-bold mb-2"
          >New Password</label
        >
        <div class="flex">
          <input
            :type="passwordFieldType"
            class="w-full bg-background border-primary py-3 px-4 border rounded-l-lg"
            name="password"
            placeholder="Enter your password"
            autocomplete="new-password"
            autocorrect="off"
            spellcheck="false"
            id="new-password"
            v-model="form.newPassword"
            required
          />
          <button
            type="button"
            class="min-w-[50px] py-3 px-4 bg-brand-darkblue text-white rounded-r-lg border-r border-y border-l-0 border-brand-yellow br-0 hover:text-brand-yellow focus:text-brand-yellow transition-colors duration-200 ease-in-out"
            @click="togglePassword"
            :aria-label="
              passwordFieldType === 'password'
                ? 'Show password'
                : 'Hide password'
            "
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
        <span
          v-if="!isPasswordComplex() && form.newPassword.length > 0"
          class="text-status-error text-center block mt-2"
          aria-live="polite"
        >
          {{ passwordErrorMessage }}
        </span>
      </div>

      <!-- Confirm Password Field -->
      <div class="mt-4">
        <label
          for="confirm-password"
          class="block text-primary font-bold mt-4 mb-2"
          >Confirm Password</label
        >
        <div class="flex">
          <input
            :type="passwordFieldType"
            class="w-full bg-background border-primary py-3 px-4 border rounded-l-lg"
            name="password"
            placeholder="Enter your password"
            autocomplete="new-password"
            autocorrect="off"
            spellcheck="false"
            id="confirm-password"
            v-model="form.confirmPassword"
            required
          />
          <button
            type="button"
            class="min-w-[50px] py-3 px-4 bg-brand-darkblue text-white rounded-r-lg border-r border-y border-l-0 border-brand-yellow br-0 hover:text-brand-yellow focus:text-brand-yellow transition-colors duration-200 ease-in-out"
            @click="togglePassword"
            :aria-label="
              passwordFieldType === 'password'
                ? 'Show password'
                : 'Hide password'
            "
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
        <span
          v-if="form.confirmPassword.length > 0 && !isPasswordMatch()"
          class="text-status-error text-center block mt-2"
          aria-live="polite"
        >
          Passwords do not match
        </span>
      </div>

      <!-- Submit Button -->
      <button
        type="submit"
        class="w-full mt-10 py-3 px-4 bg-brand-darkblue text-white border border-brand-yellow rounded-lg font-medium text-lg hover:text-brand-yellow focus:text-brand-yellow transition-colors duration-200 ease-in-out disabled:text-grey-light disabled:border-grey"
        :disabled="!isFormValid() || loading"
      >
        Reset Password
      </button>

      <!-- Feedback -->
      <div v-if="serverMessage" :class="[messageClass, 'mt-4 text-center']">
        {{ serverMessage }}
      </div>
    </form>

    <!-- Notification -->
    <NotificationCustom
      v-if="notification.message"
      :type="notification.type"
      :message="notification.message"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import apiClient from "@/api";
import NotificationCustom from "@/components/common/NotificationCustom.vue";

export default {
  components: { NotificationCustom },
  data() {
    return {
      form: {
        newPassword: "",
        confirmPassword: "",
      },
      token: "", // Token extracted from the URL
      passwordErrorMessage: "",
      serverMessage: "",
      success: false,
      error: false,
      notification: {
        message: "",
        type: "",
      },
      countdown: 10,
      redirectInterval: null,
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
    };
  },
  computed: {
    // Computed property to determine the redirect path based on authentication status
    redirectPath() {
      return this.isAuthenticated ? "/your-account" : "/log-in";
    },
    messageClass() {
      return this.success ? "text-status-success" : "text-status-error";
    },
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  mounted() {
    // Extract the token from the URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get("token");
    if (!this.token) {
      this.serverMessage = "Invalid or missing token.";
      this.error = true;
    }
  },
  beforeUnmount() {
    clearInterval(this.redirectInterval);
  },
  methods: {
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
    isPasswordComplex() {
      const { newPassword } = this.form;
      if (!newPassword) {
        return true;
      }
      const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;

      if (newPassword.length < 10) {
        this.passwordErrorMessage =
          "Password must contain at least 10 characters";
        return false;
      } else if (!symbolRegex.test(newPassword)) {
        this.passwordErrorMessage =
          "Password must contain at least one special symbol";
        return false;
      } else if (!uppercaseRegex.test(newPassword)) {
        this.passwordErrorMessage =
          "Password must contain at least one uppercase letter";
        return false;
      } else if (!lowercaseRegex.test(newPassword)) {
        this.passwordErrorMessage =
          "Password must contain at least one lowercase letter";
        return false;
      } else if (!numberRegex.test(newPassword)) {
        this.passwordErrorMessage = "Password must contain at least one number";
        return false;
      } else {
        this.passwordErrorMessage = "";
        return true;
      }
    },
    isPasswordMatch() {
      return this.form.newPassword === this.form.confirmPassword;
    },
    isFormValid() {
      return this.isPasswordComplex() && this.isPasswordMatch();
    },
    async handlePasswordReset() {
      if (!this.token) {
        this.serverMessage = "Invalid or missing token.";
        this.error = true;
        this.showNotification("error", this.serverMessage);
        return;
      }

      if (!this.isFormValid()) {
        this.serverMessage = "Please correct the errors in the form.";
        this.error = true;
        this.showNotification("error", this.serverMessage);
        return;
      }

      try {
        const response = await apiClient.patch("/auth/reset-password", {
          token: this.token,
          newPassword: this.form.newPassword,
        });
        this.serverMessage =
          response.data.message || "Password reset successfully!";
        this.success = true;
        this.error = false;
        this.showNotification("success", this.serverMessage);
        this.startRedirectCountdown();
      } catch (error) {
        this.serverMessage =
          error.response?.data?.error || "An error occurred.";
        this.error = true;
        this.success = false;
        this.showNotification("error", this.serverMessage);
      }
    },
    showNotification(type, message) {
      this.notification = { type, message };
    },
    startRedirectCountdown() {
      this.redirectInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.redirectInterval);
          this.$router.push(this.redirectPath);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
input {
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 128 255 / 0.75) !important;
}
</style>
