<template>
  <section
    class="py-6 md:py-4 theme-transition bg-background"
    :class="isRounded"
  >
    <div class="mx-auto max-w-[90dvw]">
      <div
        class="grid grid-cols-2 md:grid-cols-1 min-lg:gap-12 lg:gap-4 md:grid-order-normal"
        :class="gridOrder"
      >
        <!-- Image Column -->
        <div class="min-md:col-span-1">
          <img
            :src="imageSrc"
            :alt="imageAlt"
            class="w-full h-auto rounded-xl"
            loading="lazy"
          />
        </div>

        <!-- Text Content -->
        <div
          class="flex items-center bg-surfaceSaturated rounded-3xl p-4 text-center"
        >
          <div class="min-lg:space-y-12 min-md:space-y-8 md:space-y-6 mx-auto">
            <h2 class="text-3xl md:text-2xl font-bold text-primary align-top">
              {{ title }}
            </h2>
            <p class="text-lg text-secondary text-balance">
              {{ description }}
            </p>
            <router-link
              :disabled="disabled"
              :to="ctaLink"
              class="inline-block bg-brand-darkblue px-4 py-3 rounded-lg border !transition-none"
              :class="[
                disabled
                  ? '!text-[#c7c7c7] cursor-not-allowed border-[#c7c7c7]'
                  : ' text-white hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowborder-brand-yellow',
              ]"
            >
              <span class="font-medium">{{ ctaText }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PlatformSection",
  props: {
    imageSrc: String,
    imageAlt: String,
    title: String,
    description: String,
    ctaText: String,
    ctaLink: String,
    reverse: Boolean,
    rounded: Boolean,
    disabled: Boolean,
    backgroundClass: {
      type: String,
      default: "bg-surfaceSaturated",
    },
  },
  computed: {
    gridOrder() {
      return this.reverse ? "grid-order-reverse-md" : "grid-order-normal";
    },
    isRounded() {
      return this.rounded
        ? "rounded-t-3xl pt-25 border-t border-brand-darkblue"
        : "rounded-none";
    },
  },
};
</script>

<style scoped>
.grid-order-normal {
  order: 1;
}

.grid-order-reverse-md {
  order: 2;
}

.grid-order-normal > div:nth-child(1),
.grid-order-reverse-md > div:nth-child(2) {
  order: 1;
}

.grid-order-normal > div:nth-child(2),
.grid-order-reverse-md > div:nth-child(1) {
  order: 2;
}

@media (max-width: 768px) {
  .grid-order-reverse-md {
    order: 1 !important;
  }
  .grid-order-reverse-md > div:nth-child(2) {
    order: 2 !important;
  }
  .grid-order-reverse-md > div:nth-child(1) {
    order: 1 !important;
  }
}
</style>
