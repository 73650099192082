<template>
  <CommonHeader />
  <main>
    <div
      class="bg-background flex flex-col justify-center items-center py-30 min-h-[80dvh]"
      aria-labelledby="not-found-title"
    >
      <img
        src="/images/biglo_logo.png"
        class="w-48 h-48 mb-4 mx-auto"
        alt="Biglo's Logo"
        aria-hidden="true"
      />
      <h1
        id="not-found-title"
        class="text-primary text-3xl font-bold mx-auto text-center my-6"
      >
        The page you're looking for<br />can't be found.
      </h1>
      <router-link
        to="/"
        class="my-4 border border-brand-yellow text-white text-lg bg-brand-darkblue px-4 py-3 font-medium rounded-md shadow-md hover:text-brand-yellow focus:text-brand-yellow focus:text-brand-yellow transition-all duration-200"
        aria-label="Return to home page"
      >
        <i class="fa-solid fa-house-chimney mr-2" aria-hidden="true"></i>
        Want to return home?
      </router-link>
    </div>
  </main>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import CommonFooter from "@/components/common/Footer.vue";
export default {
  name: "NotFoundPage",
  metaInfo: {
    title: "404: Page not found | Biglo",
  },
  components: {
    CommonHeader,
    CommonFooter,
  },
};
</script>

<style scoped></style>
