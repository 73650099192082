<template>
  <CommonHeader />
  <div
    class="min-md:min-h-[calc(80dvh-86px)] min-md:px-[5dvw] mx-auto bg-background"
  >
    <RegisterSpaceOrService />
  </div>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import RegisterSpaceOrService from "@/components/others/RegisterSpaceOrService.vue";
import CommonFooter from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RegisterSpaceOrServiceView",
  metaInfo: {
    title: "Register Your Space | Biglo",
  },
  components: {
    CommonHeader,
    RegisterSpaceOrService,
    CommonFooter,
  },
});
</script>

<style scoped></style>
