<template>
  <CommonHeader />
  <!-- calc(100dvh-376px) 100dvh - (86px header + 290px footer) -->
  <main class="py-12 bg-background min-h-[calc(100dvh-376px)]">
    <UserLogin />
  </main>
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import UserLogin from "@/components/SignIn/UserLogin.vue";
import CommonFooter from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogInPage",
  metaInfo: {
    title: "Biglo | Sign In",
  },
  components: {
    CommonHeader,
    UserLogin,
    CommonFooter,
  },
});
</script>

<style scoped></style>
