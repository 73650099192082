import * as types from "../types";

export default {
  [types.SET_PASSWORD_ATTEMPT](state, payload) {
    state.failedPasswordAttempts = payload.attempts;
    state.lastPasswordAttempt = payload.timestamp;
  },

  [types.SET_USER](state, { user, tokenExpiration }) {
    state.user = user;
    state.isAuthenticated = true;
    state.tokenExpiration = tokenExpiration;

    // Store user in localStorage (optional, for persistence across page refreshes)
    localStorage.setItem("user", JSON.stringify(user));

    // Set a timer to log the user out before the token expires
    if (state.logoutTimer) {
      clearTimeout(state.logoutTimer);
    }

    const timeUntilExpiration = tokenExpiration - Date.now();

    if (timeUntilExpiration > 0) {
      state.logoutTimer = setTimeout(() => {
        this.commit("auth/LOGOUT");
      }, timeUntilExpiration);
    }
  },

  [types.SET_CSRF_TOKEN](state, token) {
    state.csrfToken = token;
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.isAuthenticated = false;
    state.userLogoBlob = null;

    // Clear user from localStorage
    localStorage.removeItem("user");

    if (state.logoutTimer) {
      clearTimeout(state.logoutTimer);
      state.logoutTimer = null;
    }

    localStorage.removeItem("_csrf");
  },

  [types.SET_USER_LOGO_BLOB](state, blob) {
    state.userLogoBlob = blob;
  },

  [types.SET_LOADING](state, isLoading) {
    state.loading = isLoading; // Update the loading state
  },
};
