<template>
  <section
    class="pt-20 pb-25 bg-surfaceSaturated theme-transition border-t border-brand-darkblue"
  >
    <div class="mx-auto max-w-90dvw text-center">
      <h2
        class="text-3xl min-md:text-4xl font-bold text-primary min-md:mb-20 md:mb-8"
      >
        TRUSTED BY LEADING COMPANIES
      </h2>

      <div class="relative overflow-hidden w-full block">
        <div class="carousel">
          <div class="carousel-track">
            <div
              v-for="(logo, index) in repeatedLogos"
              :key="index"
              class="carousel-item"
            >
              <img
                :src="logo.src"
                :alt="logo.alt"
                class="w-auto h-auto max-h-16 opacity-50 hover:opacity-100 transition-opacity"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ClientsSection",
  data() {
    return {
      logos: [
        { src: "/images/primafrio.png", alt: "Primafrio Logo" },
        { src: "/images/across.png", alt: "Across Logistics Logo" },
        { src: "/images/dhl.png", alt: "DHL Logo" },
        { src: "/images/noatum.png", alt: "Noatum Logo" },
        { src: "/images/edg.png", alt: "EDG Logo" },
        { src: "/images/sesé.png", alt: "Sesé Logo" },
        { src: "/images/grupo-caliche.png", alt: "Grupo Caliche Logo" },
        { src: "/images/adock.png", alt: "Adock Logo" },
        { src: "/images/el-mosca.png", alt: "El Mosca Logo" },
        { src: "/images/ceva.png", alt: "Ceva Logo" },
        { src: "/images/bergé.png", alt: "Bergé Logo" },
        { src: "/images/airpharm.png", alt: "Airpharm Logo" },
        { src: "/images/fm.png", alt: "FM Logo" },
        { src: "/images/esp.png", alt: "ESP Logo" },
        { src: "/images/kuehne+nagel.png", alt: "Kuehne + Nagel Logo" },
      ],
    };
  },
  computed: {
    repeatedLogos() {
      return [...this.logos, ...this.logos, ...this.logos];
    },
  },
};
</script>

<style scoped>
.carousel {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.carousel-track {
  display: flex;
  width: fit-content;
  animation: scroll 60s linear infinite;
}
@media (max-width: 768px) {
  .carousel-track {
    display: flex;
    width: fit-content;
    animation: scroll 30s linear infinite;
  }
}
.carousel-item {
  flex-shrink: 0;
  padding: 0 2rem;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-300%);
  }
}
</style>
