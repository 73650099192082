<template>
  <LandingPage />
</template>

<script>
import LandingPage from "@/components/Home/LandingPage.vue";

export default {
  components: {
    LandingPage,
  },
};
</script>

<style scoped></style>
