<template>
  <div v-if="space" class="text-brand-darkblue p-5 max-w-screen-lg mx-auto">
    <!-- Space Details Header Section -->
    <div class="flex flex-col md:flex-row items-center gap-4 mb-8">
      <div class="w-full md:w-1/3">
        <!-- Image Wrapper with Aspect Ratio -->
        <div
          class="aspect-w-16 aspect-h-9 bg-grey rounded-lg overflow-hidden shadow-lg"
        >
          <img
            :src="spaceImage"
            alt="Space Image"
            class="w-full h-full object-cover"
          />
        </div>
      </div>
      <div class="text-center md:text-left">
        <h1 class="text-2xl md:text-3xl font-semibold">
          {{ space.typeData.type }} - {{ space.locationData.city }},
          {{ space.locationData.country }}
        </h1>
        <h2 class="text-lg md:text-xl text-grey mt-2">
          {{ space.dimensionsData.surface }} m²
        </h2>
      </div>
    </div>

    <!-- Space Details Body Section -->
    <div class="bg-white p-6 rounded-lg shadow-lg space-y-6">
      <!-- Location Section -->
      <div>
        <h3 class="text-xl font-semibold mb-2">Location</h3>
        <p class="text-grey">
          {{ space.locationData.address }}, {{ space.locationData.city }},
          {{ space.locationData.region }}, {{ space.locationData.zip }}
        </p>
      </div>

      <!-- Dimensions Section -->
      <div>
        <h3 class="text-xl font-semibold mb-2">Dimensions</h3>
        <p>Surface: {{ space.dimensionsData.surface }} m²</p>
        <p>Height: {{ space.dimensionsData.height }} m</p>
        <p>Pallet Positions: {{ space.dimensionsData.palletPositions }}</p>
      </div>

      <!-- Operations Section -->
      <div>
        <h3 class="text-xl font-semibold mb-2">Operations</h3>
        <ul class="list-disc list-inside ml-4 text-grey">
          <li v-for="(value, key) in space.operationsData" :key="key">
            {{ key }}: {{ value }}
          </li>
        </ul>
      </div>

      <!-- Storage Section -->
      <div v-if="space.storageData">
        <h3 class="text-xl font-semibold mb-2">Storage</h3>
        <div
          v-for="(storageType, storageName) in space.storageData"
          :key="storageName"
          class="mt-2"
        >
          <h4 class="text-lg font-medium capitalize">
            {{ storageName.replace(/([A-Z])/g, " $1") }}
          </h4>
          <ul class="list-disc list-inside ml-4 text-grey">
            <li v-for="(value, key) in storageType" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </div>
      </div>

      <!-- Equipment Section -->
      <div>
        <h3 class="text-xl font-semibold mb-2">Equipment</h3>
        <ul class="list-disc list-inside ml-4 text-grey">
          <li v-for="(value, key) in space.equipmentData" :key="key">
            {{ key }}: {{ value }}
          </li>
        </ul>
      </div>

      <!-- Goods Section -->
      <div>
        <h3 class="text-xl font-semibold mb-2">Goods</h3>
        <ul class="list-disc list-inside ml-4 text-grey">
          <li v-for="(value, key) in space.goodsData" :key="key">
            {{ key }}: {{ value }}
          </li>
        </ul>
      </div>

      <!-- Licenses Section -->
      <div>
        <h3 class="text-xl font-semibold mb-2">Licenses</h3>
        <ul class="list-disc list-inside ml-4 text-grey">
          <li v-for="(value, key) in space.licensesData" :key="key">
            {{ key }}: {{ value }}
          </li>
        </ul>
      </div>

      <!-- Description Section -->
      <div>
        <h3 class="text-xl font-semibold mb-2">Description</h3>
        <p class="text-grey">{{ space.descriptionData.descriptionText }}</p>
      </div>
    </div>
  </div>

  <!-- Loading Message -->
  <div v-else class="text-center text-grey text-lg py-10">
    Loading space details...
  </div>
</template>

<script>
import apiClient from "@/api";

export default {
  name: "SpaceDetails",
  data() {
    return {
      space: null,
      spaceId: this.$route.params.spaceId,
    };
  },
  computed: {
    spaceImage() {
      return (
        this.space?.multimediaData?.images?.uploaded?.[0] ||
        require("@/assets/images/free-advertisement.png")
      );
    },
  },
  created() {
    this.fetchSpaceDetails();
  },
  methods: {
    async fetchSpaceDetails() {
      try {
        const response = await apiClient.get(`/spaces/${this.spaceId}`);
        this.space = response.data;
      } catch (error) {
        console.error("Error fetching space details:", error);
      }
    },
  },
};
</script>

<style scoped></style>
