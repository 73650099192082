<template>
  <section
    class="py-25 bg-background theme-transition rounded-t-3xl border-t border-brand-darkblue"
  >
    <div class="mx-auto max-w-[90dvw]">
      <h2
        class="text-3xl min-md:text-4xl font-bold text-primary text-center mb-16"
      >
        LATEST ARTICLES
      </h2>

      <div class="grid min-md:grid-cols-2 gap-12">
        <article
          v-for="(article, index) in articles"
          :key="index"
          class="group relative overflow-hidden rounded-xl hover:shadow-lg hover:shadow-status-info/50 transition-shadow"
        >
          <img
            :src="article.image"
            :alt="article.title"
            class="w-full h-80 object-cover"
          />
          <div class="p-8 bg-surfaceSaturated">
            <div class="flex items-center gap-4 mb-4">
              <span>{{ article.icon }}</span>
              <span class="font-semibold text-invertedAlt">
                {{ article.category }}
              </span>
            </div>
            <h3
              class="text-xl font-bold text-primary mb-4 group-hover:text-inverted text-balanced"
            >
              {{ article.title }}
            </h3>
          </div>
        </article>
      </div>

      <div class="text-center mt-16">
        <router-link
          to="/blog"
          class="inline-flex bg-brand-darkblue border border-brand-yellow px-4 py-3 rounded-lg items-center gap-2 text-primary hover:text-brand-yellow focus:text-brand-yellow !transition-none"
        >
          <span class="font-medium">Discover more Insights</span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlogSection",
  data() {
    return {
      articles: [
        {
          title: "Logistics Trends 2024: Prepare Your Business for the Future",
          image: "/images/still-life-supply-chain-representation-1.png",
          category: "Trends",
          icon: "🔥",
        },
        {
          title: "How to Choose the Right Logistics Provider for Your Business",
          image: "/images/plane-boxes-arrangement-1.png",
          category: "Guides",
          icon: "📖",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
