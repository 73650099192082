<template>
  <CommonHeader />
  <router-view />
  <CommonFooter />
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import CommonFooter from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BlogView",
  metaInfo: {
    title: "Blog | Biglo",
  },
  components: {
    CommonHeader,
    CommonFooter,
  },
});
</script>

<style scoped></style>
