<template>
  <div class="flex flex-col min-h-screen bg-background relative">
    <!-- Main Content -->
    <main
      class="relative flex flex-col min-lg:flex-row flex-grow gap-4 p-4"
      role="main"
    >
      <!-- Filters Toggle Button for Small Screens -->
      <button
        @click="toggleFilters"
        class="bg-brand-darkblue text-white hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellowfont-semibold py-2 px-4 rounded shadow-lg min-lg:hidden"
        aria-label="Toggle filters"
        :aria-expanded="isFiltersVisible"
      >
        Filters
      </button>

      <!-- Filters Section -->
      <aside
        :class="[
          'bg-surface p-6 shadow-lg rounded-lg border border-brand-darkblue flex-shrink-0 transition-all duration-300',
          isFiltersVisible
            ? 'mx-auto z-10 w-full h-full md:max-w-md'
            : 'hidden',
          'min-lg:block min-lg:relative min-lg:top-auto min-lg:left-auto min-lg:z-auto min-lg:w-1/4',
        ]"
        aria-label="Filters"
      >
        <ServicesSearchFilters @apply-filters="handleFiltersApplied" />
      </aside>

      <!-- Results Section -->
      <section
        class="flex-grow bg-surface p-6 shadow-lg rounded-lg border border-brand-darkblue"
        aria-label="Search results"
      >
        <!-- Add a ref to the Results component -->
        <ServicesSearchResults ref="results" />
      </section>
    </main>
  </div>
</template>

<script>
import ServicesSearchFilters from "@/components/Search/Services/Filters.vue";
import ServicesSearchResults from "@/components/Search/Services/Results.vue";

export default {
  name: "ServicesSearch",
  components: {
    ServicesSearchFilters,
    ServicesSearchResults,
  },
  data() {
    return {
      isFiltersVisible: false, // State for filters visibility
    };
  },
  methods: {
    toggleFilters() {
      this.isFiltersVisible = !this.isFiltersVisible;
      if (this.isFiltersVisible) {
        this.$nextTick(() => {
          const firstFilter = this.$el.querySelector(
            "aside input, aside select",
          );
          if (firstFilter) firstFilter.focus();
        });
      }
    },
    handleFiltersApplied(filters) {
      const transformedFilters = {};

      // Function to filter out false values
      const filterFalseValues = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
          if (obj[key] === true) {
            acc[key] = obj[key];
          }
          return acc;
        }, {});
      };

      // Only include filters that are explicitly toggled
      if (Object.keys(filters.plType).length > 0) {
        const filteredPlType = filterFalseValues(filters.plType);
        if (Object.keys(filteredPlType).length > 0) {
          transformedFilters.plType = JSON.stringify(filteredPlType);
        }
      }
      if (filters.location.search) {
        transformedFilters[filters.location.type] = filters.location.search;
      }
      if (filters.location.internationalCoverage) {
        transformedFilters.internationalCoverage = JSON.stringify(
          filters.location.continents,
        );
      }
      if (Object.keys(filters.sectorIndustry).length > 0) {
        const filteredSectorIndustry = filterFalseValues(
          filters.sectorIndustry,
        );
        if (Object.keys(filteredSectorIndustry).length > 0) {
          transformedFilters.sectorIndustry = JSON.stringify(
            filteredSectorIndustry,
          );
        }
      }
      if (Object.keys(filters.transportation).length > 0) {
        const filteredTransportation = filterFalseValues(
          filters.transportation,
        );
        if (Object.keys(filteredTransportation).length > 0) {
          transformedFilters.transportation = JSON.stringify(
            filteredTransportation,
          );
        }
      }
      if (Object.keys(filters.storageAndInventory).length > 0) {
        const filteredStorageAndInventory = filterFalseValues(
          filters.storageAndInventory,
        );
        if (Object.keys(filteredStorageAndInventory).length > 0) {
          transformedFilters.storageAndInventory = JSON.stringify(
            filteredStorageAndInventory,
          );
        }
      }
      if (Object.keys(filters.contract).length > 0) {
        const filteredContract = filterFalseValues(filters.contract);
        if (Object.keys(filteredContract).length > 0) {
          transformedFilters.contract = JSON.stringify(filteredContract);
        }
      }
      if (Object.keys(filters.ordersManagement).length > 0) {
        const filteredOrdersManagement = filterFalseValues(
          filters.ordersManagement,
        );
        if (Object.keys(filteredOrdersManagement).length > 0) {
          transformedFilters.ordersManagement = JSON.stringify(
            filteredOrdersManagement,
          );
        }
      }

      console.log("Transformed Filters:", transformedFilters);
      this.$refs.results.fetchServices(transformedFilters);
    },
  },
};
</script>

<style scoped>
button:focus {
  outline: 2px solid currentColor;
  outline-offset: 2px;
}
</style>
