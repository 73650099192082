<template>
  <div class="bg-background">
    <!-- Header section with shadow effect -->
    <div class="shadow-md">
      <CommonHeader />
    </div>

    <!-- Wrapper for main account content, centered with max-width control -->
    <main class="mt-4 max-w-[95dvw] md:max-w-full md:m-4 mx-auto">
      <YourAccount />
    </main>
  </div>
</template>

<script>
import CommonHeader from "@/components/common/Header.vue";
import YourAccount from "@/components/Account/YourAccount.vue";

export default {
  name: "YourAccountView",
  components: {
    CommonHeader,
    YourAccount,
  },
};
</script>

<style scoped></style>
