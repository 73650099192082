<template>
  <section
    class="py-20 md:py-10 bg-surfaceSaturated rounded-t-3xl theme-transition border-t border-brand-darkblue"
  >
    <div class="mx-auto max-w-90dvw text-center">
      <div class="max-w-3xl mx-auto space-y-12">
        <h2 class="text-3xl min-md:text-4xl font-bold text-primary">
          CONNECTING COMPANIES, OPTIMIZING LOGISTICS AND REDUCING COSTS
        </h2>
        <p class="text-lg text-secondary text-balance">
          Reserve your space and unlock access to our entire network of
          logistics providers and partner companies.
        </p>
        <router-link
          to="/our-mission"
          class="inline-block border border-brand-yellow bg-brand-darkblue px-4 py-3 rounded-lg text-white hover:text-brand-yellow focus:text-brand-yellow focus-visible:text-brand-yellow!transition-none"
        >
          <span class="font-medium">Biglo's Mission</span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MissionSection",
};
</script>
