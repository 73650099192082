<template>
  <div
    class="flex flex-col items-center justify-center border-2 border-dashed border-white hover:border-brand-yellow focus-within:border-brand-yellow rounded-lg"
  >
    <!-- Dropzone -->
    <label
      v-if="!selectedFile"
      class="p-8 w-full text-center cursor-pointer flex flex-col items-center justify-center hover:text-brand-yellow focus-within:text-brand-yellow"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
    >
      <input
        tabindex="0"
        id="dropzone"
        type="file"
        ref="fileInput"
        :accept="allowedMimeTypes.join(',')"
        name="file"
        @change="handleFileSelect"
        aria-label="Company logo upload. Supported formats: PNG, JPEG, SVG, WEBP, GIF, TIFF. Max size 10MB"
      />
      <i class="fas fa-file-arrow-up fa-3x"></i>
      <p class="mt-4">
        <span class="lg:hidden">Drag & Drop or </span>Click to Upload your Logo
      </p>
    </label>

    <!-- Preview section -->
    <div v-if="selectedFile" class="flex flex-col items-center p-8">
      <div class="relative">
        <img
          :src="processedUrl"
          class="max-h-32 max-w-full rounded"
          alt="Logo preview"
          aria-describedby="file-info"
        />
        <button
          @click="clearSelection"
          class="absolute -top-2 -right-2 bg-status-error text-white rounded-full w-6 h-6 flex items-center justify-center transition-colors"
          aria-label="Remove logo"
          :aria-disabled="isUploading"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <p id="file-info" class="mt-4 text-white font-semibold">
        {{ selectedFile.name }}
      </p>
    </div>

    <!-- Upload progress and status messages -->
    <div
      v-if="uploadProgress > 0 || uploadSuccess || uploadError"
      role="status"
      aria-live="polite"
      aria-atomic="true"
      class="mt-4 w-full max-w-lg"
    >
      <div
        v-if="uploadProgress > 0 && uploadProgress < 100"
        class="w-full bg-gray-200 rounded-full h-2.5"
      >
        <div
          class="bg-brand-darkblue h-2.5 rounded-full"
          :style="{ width: uploadProgress + '%' }"
        ></div>
        <p class="text-center text-brand-darkblue mt-1">
          Uploading: {{ uploadProgress }}%
        </p>
      </div>
      <p v-if="uploadSuccess" class="text-center text-status-success">
        <i class="fas fa-check-circle mr-1"></i>
        File uploaded successfully!
      </p>
      <p v-if="uploadError" class="text-center text-status-error">
        <i class="fas fa-exclamation-circle mr-1"></i>
        {{ uploadError }}
      </p>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api";

export default {
  name: "DropzoneLogo",
  data() {
    return {
      selectedFile: null,
      processedUrl: null,
      isUploading: false,
      uploadProgress: 0,
      uploadSuccess: false,
      uploadError: null,
      allowedMimeTypes: [
        "image/png",
        "image/jpeg",
        "image/svg+xml",
        "image/webp",
        "image/gif",
        "image/tiff",
      ],
      maxFileSize: 10 * 1024 * 1024, // 10 MB
    };
  },
  methods: {
    // Handle file selection from input and automatically upload
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.validateFile(file);
      if (this.selectedFile) {
        this.uploadFile(); // Automatically trigger the upload
      }
    },

    // Handle drag-and-drop file selection
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.validateFile(file);
      if (this.selectedFile) {
        this.uploadFile(); // Automatically trigger the upload
      }
    },

    // Visual feedback for dragging over the drop zone
    dragOver(event) {
      event.currentTarget.classList.add("bg-status-info", "text-brand-yellow");
    },

    // Visual feedback when leaving the drop zone
    dragLeave(event) {
      event.currentTarget.classList.remove(
        "bg-status-info",
        "text-brand-yellow",
      );
    },

    // Validate the selected file
    validateFile(file) {
      if (!file) return;

      // Clear previous state
      this.clearPreview();
      this.uploadError = null;

      // Check file type
      if (!this.allowedMimeTypes.includes(file.type)) {
        this.uploadError = `Only these image formats are allowed: ${this.allowedMimeTypes
          .map((type) => type.split("/")[1].toUpperCase())
          .join(", ")}`;
        this.selectedFile = null;
        return;
      }

      // Check file size
      if (file.size > this.maxFileSize) {
        this.uploadError = `File size exceeds the ${this.maxFileSize / (1024 * 1024)}MB limit.`;
        this.selectedFile = null;
        return;
      }

      // Additional validation for SVG files
      if (file.type === "image/svg+xml") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const svgContent = e.target.result;
          if (!this.isValidSvg(svgContent)) {
            this.uploadError = "Invalid SVG file content";
            this.selectedFile = null;
            return;
          }
          this.setFile(file);
        };
        reader.readAsText(file);
      } else {
        this.setFile(file);
      }
    },

    // Set the file and create preview
    setFile(file) {
      this.selectedFile = file;
      this.uploadError = null; // Clear previous errors
      this.createPreview(file);
    },

    // Basic SVG validation
    isValidSvg(svgContent) {
      // Basic checks for SVG structure
      const isValid = /<svg[^>]*>.*<\/svg>/gis.test(svgContent);

      // Additional security checks (optional)
      const unsafePatterns = [
        /<script/gi,
        /javascript:/gi,
        /data:/gi,
        /onload=/gi,
      ];

      return (
        isValid && !unsafePatterns.some((pattern) => pattern.test(svgContent))
      );
    },

    // Clear file selection and preview
    clearSelection() {
      this.selectedFile = null;
      this.processedUrl = null;
      this.clearPreview();
      this.uploadSuccess = false;
      this.uploadError = null;
      this.$emit("file-uploaded", null);
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },

    // Upload the selected file to the backend
    async uploadFile() {
      if (!this.selectedFile) return;

      this.isUploading = true;
      this.uploadProgress = 0;
      this.uploadSuccess = false;
      this.uploadError = null;
      this.processedUrl = null; // Reset processed URL

      const formData = new FormData();
      formData.append("logo", this.selectedFile);

      try {
        const response = await apiClient.post(
          "/images/logos/upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              this.uploadProgress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
            },
          },
        );

        if (response.status === 201) {
          this.uploadSuccess = true;
          const gcsUrl = response.data.data.gcsUrl;
          this.processedUrl = gcsUrl; // Store processed URL
          this.$emit("file-uploaded", gcsUrl);
        }
      } catch (error) {
        console.error("Upload error:", error);
        this.uploadError =
          error.response?.data?.error ||
          "An error occurred during file upload.";
      } finally {
        this.isUploading = false;
      }
    },
  },
  beforeUnmount() {
    // Clean up object URLs when component is destroyed
    this.clearPreview();
  },
};
</script>

<style scoped>
#dropzone {
  position: absolute;
  left: -9999px;
}
</style>
