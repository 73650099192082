<template>
  <!-- Container -->
  <div class="flex flex-col min-md:flex-row container-your-account">
    <!-- Aside Section - 33% width on large screens, full width on smaller screens -->
    <AsideSection
      @section-change="displayDetails"
      :current-section="currentSection"
      class="md:w-full min-md:w-1/3 min-lg:w-1/4 min-lg:my-4 min-lg:mr-6 min-md:my-4 min-md:mr-4 md:mb-4"
    />
    <!-- Main Content - 67% width on large screens, full width on smaller screens -->
    <div
      class="flex-1 md:pt-4 min-md:p-4 min-lg:pl-6 min-md:border-l md:border-t border-primary"
    >
      <component :is="currentSectionComponent" />
    </div>
  </div>
</template>

<script>
import AsideSection from "./AsideSection.vue";
import UserDetails from "./UserDetails.vue";
import MessageCenter from "./MessageCenter.vue";
import MyFavourites from "./MyFavourites.vue";
import SavedSearches from "./SavedSearches.vue";
import SpacesManagement from "./SpacesManagement.vue";
import ServicesManagement from "./ServicesManagement.vue";
import AdsManagement from "./AdsManagement.vue";

export default {
  components: {
    AsideSection,
    UserDetails,
    MessageCenter,
    MyFavourites,
    SavedSearches,
    SpacesManagement,
    ServicesManagement,
    AdsManagement,
  },
  data() {
    return {
      currentSection: "UserDetails",
    };
  },
  computed: {
    currentSectionComponent() {
      return this.currentSection;
    },
  },
  methods: {
    displayDetails(section) {
      this.currentSection = section;
    },
  },
};
</script>

<style scoped>
.container-your-account {
  min-height: calc(100dvh - 86px);
}
</style>
