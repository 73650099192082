function getStoredUser() {
  try {
    const user = localStorage.getItem("user");
    if (!user) return null;
    return JSON.parse(user);
  } catch (error) {
    console.error("Error parsing stored user:", error);
    localStorage.removeItem("user");
    return null;
  }
}

const storedUser = getStoredUser();

export default () => ({
  isAuthenticated: !!storedUser,
  user: storedUser,
  userLogoBlob: null,
  failedPasswordAttempts: 0,
  lastPasswordAttempt: null,
  logoutTimer: null,
  csrfToken: null,
  loading: false,
});
