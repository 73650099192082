<template>
  <div
    class="bg-surfaceSaturatedLight text-primary md:p-4 min-md:p-8 mx-auto rounded-2xl max-w-lg"
  >
    <h1
      class="text-2xl font-semibold text-center mb-8 flex items-center justify-center space-x-2"
    >
      <i class="fa-solid fa-user-plus" aria-hidden="true"></i>
      <span>User Registry</span>
    </h1>
    <form id="user-form" @submit.prevent="handleSubmit">
      <!-- Company Name -->
      <label for="name" class="block font-medium mb-1 mt-4">Company Name</label>
      <input
        ref="name"
        class="w-full bg-background border-primary py-3 px-4 border rounded-lg"
        type="text"
        id="name"
        v-model="form.name"
        required
        autocomplete="on"
        autocorrect="off"
        placeholder="Enter company name"
        minlength="2"
        maxlength="40"
      />

      <!-- Email -->
      <label for="email" class="block font-medium mb-1 mt-4">E-mail</label>
      <input
        class="w-full bg-background border-primary py-3 px-4 border rounded-lg"
        type="email"
        id="email"
        autocomplete="on"
        autocorrect="off"
        v-model="form.email"
        placeholder="Enter email"
        required
        pattern="^[^\s@+]+@[^\s@]+\.[^\s@]{2,}$"
        title="Please match the requested format. No '+' allowed."
      />

      <!-- Telephone -->
      <label for="telephone" class="block font-medium mb-1 mt-4"
        >Telephone</label
      >
      <div class="flex">
        <!-- Country Code Input with Flag Picker -->
        <TelephoneCountryPrefixCustom
          v-model="form.telephoneCountryPrefix"
          @country-selected="focusInputIfInvalid('telephone')"
          class="min-md:min-w-[33.33%] md:min-w-[144px]"
        />
        <!-- Telephone Number Input -->
        <input
          class="min-md:w-4/6 min-w-[125px] py-3 px-4 border border-primary bg-background rounded-r-lg"
          type="tel"
          id="telephone"
          v-model="form.telephone"
          ref="telephone"
          required
          pattern="^\d{7,15}$"
          placeholder="Enter phone"
          @input="restrictToDigits"
        />
      </div>

      <!-- Website -->
      <label for="website" class="block font-medium mb-1 mt-4"
        >Website Address</label
      >
      <input
        ref="website"
        type="url"
        id="website"
        autocomplete="on"
        autocorrect="off"
        v-model="form.website"
        required
        placeholder="Company's website"
        class="w-full bg-background border-primary py-3 px-4 border rounded-lg"
      />

      <!-- Country -->
      <div class="relative mt-4">
        <p class="block font-medium mb-1">Country</p>
        <CountryInput
          id="country-input"
          v-model="form.country"
          @country-selected="
            (focusInputIfInvalid('vat'), (form.country = $event))
          "
          required
        />
      </div>

      <!-- VAT/Tax ID -->
      <label for="vat" class="block font-medium mb-1 mt-4">VAT/Tax ID</label>
      <input
        ref="vat"
        type="text"
        id="vat"
        v-model="form.vat"
        required
        autocomplete="on"
        autocorrect="off"
        placeholder="Company's VAT number (or equivalent)"
        class="w-full bg-background border-primary py-3 px-4 border rounded-lg"
        minlength="5"
        maxlength="21"
      />

      <!-- Logo -->
      <p class="block font-medium mb-1 mt-4">
        Logo
        <span class="font-normal text-secondary text-sm"
          >(Not required, you can upload it later)</span
        >
      </p>
      <DropzoneLogo @file-uploaded="handleFileUploaded" class="mb-5" />

      <!-- Password -->
      <div>
        <label for="password" class="block font-medium mb-1">Password</label>
        <div class="flex">
          <input
            @focus="handlePasswordFocus()"
            @blur="handlePasswordBlur()"
            ref="password"
            :type="passwordFieldType"
            autocomplete="off"
            autocorrect="off"
            class="w-full bg-background border-primary py-3 px-4 border rounded-l-lg"
            name="password"
            id="password"
            placeholder="Enter complex password"
            v-model="form.password"
            minlength="12"
            maxlength="64"
            required
            pattern="(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])[A-Za-z\d\W_]+"
            title="At least 12 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special symbol are required."
          />
          <button
            type="button"
            class="min-w-[50px] py-3 px-4 bg-brand-darkblue text-white rounded-r-lg border-r border-y border-l-0 border-brand-yellow br-0 hover:text-brand-yellow focus:text-brand-yellow transition-colors duration-200 ease-in-out"
            @click="togglePassword"
            :aria-label="
              passwordFieldType === 'password'
                ? 'Show password'
                : 'Hide password'
            "
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
        <span
          v-if="!isPasswordComplex() && form.password.length > 0"
          class="text-center block mt-3 px-3"
          :class="`${passwordErrorMessageColor}`"
        >
          {{ passwordErrorMessage }}
        </span>
      </div>

      <!-- Submit Button -->
      <button
        type="submit"
        class="w-full mt-10 py-3 px-4 bg-brand-darkblue text-white border border-brand-yellow rounded-lg font-medium text-lg hover:text-brand-yellow focus:text-brand-yellow transition-colors duration-200 ease-in-out"
      >
        Register User
      </button>
    </form>

    <!-- Notifications -->
    <NotificationCustom
      v-for="(notification, index) in notifications"
      :key="index"
      :type="notification.type"
      :message="notification.message"
      :duration="notification.duration"
      @hide="removeNotification(index)"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CountryInput from "@/components/common/Forms/CountryInput.vue";
import NotificationCustom from "@/components/common/NotificationCustom.vue";
import DropzoneLogo from "@/components/common/DropzoneLogo.vue";
import TelephoneCountryPrefixCustom from "@/components/common/Forms/TelephoneCountryPrefixCustom.vue";

export default {
  name: "RegisterUser",
  components: {
    CountryInput,
    DropzoneLogo,
    NotificationCustom,
    TelephoneCountryPrefixCustom,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        telephoneCountryPrefix: "",
        telephone: "",
        website: "",
        country: "",
        vat: "",
        logo: null,
        password: "",
      },
      passwordErrorMessage: "",
      passwordErrorMessageColor: "",
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
      selectedFile: null,
      notifications: [],
    };
  },
  methods: {
    // Main form handling
    async handleSubmit() {
      try {
        // Call Vuex action for registration
        const response = await this.register(this.form);

        // Notify user of success and redirect
        this.addNotification(
          "success",
          response.message || "Registration successful!",
        );
      } catch (error) {
        // Display error notification
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An error occurred during registration.";
        this.addNotification("error", errorMessage);
      }
    },

    // Form field utilities
    focusInputIfInvalid(refName) {
      this.$nextTick(() => {
        const input = this.$refs[refName];
        if (!input.value || !input.checkValidity()) {
          input.focus();
        }
      });
    },

    // Restrict to digits
    restrictToDigits(event) {
      const input = event.target.value;
      this.form.telephone = input.replace(/\D/g, "");
    },

    // Password handling
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },

    isPasswordComplex() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])[A-Za-z\d\W_]+$/;
      if (!passwordRegex.test(this.form.password)) {
        this.passwordErrorMessage =
          "At least 10 characters, 1 uppercase, 1 lowercase, 1 digit and 1 special symbol are required.";
        return false;
      }
      this.passwordErrorMessage = "";
      return true;
    },

    handlePasswordFocus() {
      this.passwordErrorMessageColor = "text-status-warning";
    },
    handlePasswordBlur() {
      this.passwordErrorMessageColor = "text-status-error";
    },

    // File upload handling
    handleFileUploaded(fileUrl) {
      this.form.logo = fileUrl;
    },

    // Notification management
    addNotification(type, message, duration = 10000) {
      this.notifications.push({ type, message, duration });
    },

    removeNotification(index) {
      this.notifications.splice(index, 1);
    },

    // Vuex actions
    ...mapActions("auth", ["register"]),
  },
};
</script>

<style scoped>
input {
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 128 255 / 0.75) !important;
}
input:focus:invalid {
  border-color: #f7b500;
  box-shadow: 0 2px 10px 0.1px rgb(247 181 0 / 0.75);
}
input:not(:focus):user-invalid {
  border-color: #e60000;
}
input:user-valid {
  border-color: #00d112 !important;
}
input:focus:user-valid {
  border-color: #00d112 !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 209 18 / 0.75) !important;
}
input:valid {
  border-color: #00d112 !important;
}
input:focus:valid {
  border-color: #00d112 !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 209 18 / 0.75) !important;
}
</style>
