import axios from "axios";
import store from "@/store";
import router from "@/router";

const apiClient = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

// Add a request interceptor to include the CSRF token in state-changing requests
apiClient.interceptors.request.use((config) => {
  /**
  if (
    ["post", "put", "patch", "delete"].includes(config.method.toLowerCase())
  ) {
    const csrfToken = store.getters["auth/csrfToken"]; // Get the CSRF token from Vuex
    if (csrfToken) {
      config.headers["X-CSRF-Token"] = csrfToken; // Include the token in the header
    }
  }
  */
  return config;
});

// Add a response interceptor to catch and log errors
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API call failed: ", error.response?.data || error.message);

    // Handle 401 Unauthorized errors
    if (error.response) {
      if (error.response.status === 401) {
        // Handle unauthorized access (e.g., token expired)
        store.commit("auth/LOGOUT"); // Clear the user state
        router.push("/log-in"); // Redirect to the login page
      }
    } else {
      // Handle other types of errors (e.g., network errors)
      console.error("An error occurred:", error.message);
    }

    return Promise.reject(error);
  },
);

export default apiClient;
