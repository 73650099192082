<template>
  <main
    class="bg-surfaceSaturatedLight text-primary md:p-4 min-md:p-8 mx-auto rounded-2xl max-w-lg text-center"
  >
    <h1 class="font-semibold text-primary text-2xl px-4 py-3">
      <i class="fa-solid fa-user-shield mr-1" aria-hidden="true"></i>Password
      Forgotten
    </h1>

    <!-- Status message for screen readers -->
    <div
      v-if="message"
      class="sr-only"
      :aria-live="isError ? 'assertive' : 'polite'"
    >
      {{ message }}
    </div>

    <p class="font-medium text-secondary mt-5" v-if="!emailSent">
      Enter your email below, and we'll send you a link to reset your password.
    </p>

    <form v-if="!emailSent" @submit.prevent="sendResetEmail" class="mt-4">
      <label for="email" class="sr-only">Email address</label>
      <input
        @input="handleEmailInput"
        class="w-full bg-background border-primary py-3 px-4 my-4 border rounded-lg"
        type="email"
        id="email"
        name="email"
        autocomplete="email"
        v-model="email"
        placeholder="Enter email"
        inputmode="email"
        autocapitalize="off"
        autocorrect="off"
        spellcheck="false"
        required
        pattern="^[^\s@+]+@[^\s@]+\.[^\s@]{2,}$"
        title="Please, enter a valid email. No '+' allowed."
        :disabled="loading"
        :aria-describedby="message ? 'error-message' : undefined"
        :aria-invalid="isError"
      />

      <button
        class="bg-brand-darkblue text-white border font-medium border-brand-yellow mt-2 px-4 py-2 rounded hover:text-brand-yellow focus:text-brand-yellow disabled:bg-grey disabled:text-status-warning disabled:cursor-not-allowed disabled:hover:text-status-error disabled:hover:border-status-error disabled:hover:bg-grey-light transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow"
        type="submit"
        :disabled="loading || !isValidEmail"
        :aria-busy="loading"
        :title="
          !isValidEmail ? 'Please, enter a valid email. No + allowed.' : ''
        "
        aria-label="Submit email for password reset button"
      >
        <span v-if="loading" aria-hidden="true">Sending...</span>
        <span v-else>Send Reset Email</span>
      </button>
    </form>

    <p
      v-if="message"
      :id="isError ? 'error-message' : undefined"
      class="mt-4 font-medium"
      :class="{ 'text-status-success': !isError, 'text-status-error': isError }"
      role="alert"
      :aria-live="isError ? 'assertive' : 'polite'"
    >
      {{ message }}
    </p>
  </main>
</template>

<script>
import apiClient from "@/api";
import { debounce } from "lodash";

export default {
  name: "PasswordForgotten",
  data() {
    return {
      email: "", // User email input
      loading: false, // Button loading state
      message: "", // Feedback message
      isError: false, // Whether the feedback is an error
      emailSent: false, // Track if the reset email was sent
      forceValidation: false,
    };
  },
  computed: {
    // Computed property to validate the email
    isValidEmail() {
      this.forceValidation;
      const emailPattern = /^[^\s@+]+@[^\s@]+\.[^\s@]{2,}$/;
      return emailPattern.test(this.email);
    },
  },
  watch: {
    email: {
      immediate: true,
      handler: debounce(function () {
        this.forceValidation = !this.forceValidation;
      }, 300),
    },
  },
  methods: {
    handleEmailInput() {
      // This forces computed property re-evaluation
      this.$nextTick(() => {});
    },
    async sendResetEmail() {
      this.loading = true; // Start loading state
      this.message = ""; // Reset any previous message

      try {
        const response = await apiClient.post("/auth/forgot-password", {
          email: this.email,
        });

        this.message = response.data.message; // Set success message
        this.isError = false; // Not an error
        this.emailSent = true; // Mark email as sent
      } catch (error) {
        console.error("Error response:", error.response);
        console.error("Error message:", error.message);
        console.error("Error config:", error.config);
        this.message =
          error.response?.data?.error || "An error occurred. Please try again.";
        this.isError = true; // Mark as error
      } finally {
        this.loading = false; // Stop loading state
      }
    },
  },
};
</script>

<style scoped>
input {
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}
input:focus:placeholder-shown {
  border-color: #0080ff !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 128 255 / 0.75) !important;
}
input:focus:invalid {
  border-color: #f7b500;
  box-shadow: 0 2px 10px 0.1px rgb(247 181 0 / 0.75);
}
input:not(:focus):user-invalid {
  border-color: #e60000;
}
input:user-valid {
  border-color: #00d112 !important;
}
input:focus:user-valid {
  border-color: #00d112 !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 209 18 / 0.75) !important;
}
input:valid {
  border-color: #00d112 !important;
}
input:focus:valid {
  border-color: #00d112 !important;
  box-shadow: 0 2px 10px 0.1px rgb(0 209 18 / 0.75) !important;
}
</style>
